import { Auth } from '../../types';
import { Outlet } from 'react-router-dom';

export default function Markets() {
  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <li className="breadcrumb-item active">Markets</li>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
}
