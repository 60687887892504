import React from 'react';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import './Pricing.css'; // Import the CSS file for custom styling

const Pricing = () => {
  return (
    <Container className="my-5" id="pricing">
      <h2 className="text-center">Pricing Plans</h2>
      <p className="lead text-center text-muted mb-5">
        Base plans below plus $3/month per stakeholder
      </p>
      <Row className="justify-content-center">
        {/* Free Plan */}
        <Col md={4} className="mb-4">
          <Card className="h-100 text-center shadow pricing free">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="mb-4 fs-3">Starter Plan</Card.Title>
              <h3 className="card-price display-4 text-dark mb-4">
                $0<span className="period fs-4">/mo.</span>
              </h3>
              <Card.Text className="mt-2 flex-grow-1">
                <ul className="list-unstyled">
                  <li className="mb-3">Best for individual investors</li>
                  <li className="mb-3">Accredited investor certification</li>
                  <li className="mb-3">Invest in SAFE and Equity offerings</li>
                </ul>
              </Card.Text>
              <Button
                variant="outline-dark"
                size="lg"
                className="mt-auto"
                href="https://6v1qwdajlxv.typeform.com/to/PrLYKTM9"
                target="_blank"
              >
                Get Started
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Bootstrap Plan */}
        <Col md={4} className="mb-4">
          <Card className="h-100 text-center shadow pricing border-primary border-2 pro">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="mb-4 fs-3">Seed Plan</Card.Title>
              <h3 className="card-price display-4 text-primary mb-4">
                $99<span className="period fs-4">/mo.</span>
              </h3>
              <Card.Text className="mt-2 flex-grow-1">
                <ul className="list-unstyled">
                  <li className="mb-3">Everything in Starter, plus...</li>
                  <li className="mb-3">
                    Tokenize an entity (C corp, LLC, trust)
                  </li>
                  <li className="mb-3">Fundraise with SAFEs and SPVs</li>
                  <li className="mb-3">Issue securities manually</li>
                  <li className="mb-3">Email support</li>
                  <li className="mb-3 text-muted">
                    Employee stock options{' '}
                    <Badge bg="secondary">Coming Soon</Badge>
                  </li>
                </ul>
              </Card.Text>
              <Button
                variant="outline-dark"
                size="lg"
                className="mt-auto"
                href="https://6v1qwdajlxv.typeform.com/to/PrLYKTM9"
                target="_blank"
              >
                Get Started
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Scale Plan */}
        <Col md={4} className="mb-4">
          <Card className="h-100 text-center shadow pricing border-success border-2 pro-plus">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="mb-4 fs-3">Scale Plan</Card.Title>
              <h3 className="card-price display-4 text-success mb-4">
                Let's talk
              </h3>
              <Card.Text className="mt-2 flex-grow-1">
                <ul className="list-unstyled">
                  <li className="mb-3">Everything in Seed, plus...</li>
                  <li className="mb-3">Priced equity investments</li>
                  <li className="mb-3">Import and sync cap table</li>
                  <li className="mb-3">Manage stakeholder liquidity</li>
                  <li className="mb-3">Premium support</li>
                  <li className="mb-3">Advanced security types</li>
                </ul>
              </Card.Text>
              <Button variant="success" size="lg" className="mt-auto" disabled>
                Coming Soon
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Pricing;
