// capsign.com/src/pages/Equity/Drafts/SafeForm.tsx

import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import { useEntity } from '../../../contexts/EntityContext';
import axios from 'axios';
import ModuleDataForm from '../../Identities/ManageCertificateForm/ModuleDataForm';
import { ModuleData } from '../../../types/moduleData';
import { useAccount, useChainId, usePublicClient } from 'wagmi';
import { useCallsStatus, useWriteContracts } from 'wagmi/experimental';
import { contracts } from '../../../constants';
import { decodeEventLog, zeroAddress } from 'viem';
import { Abi } from 'viem';
import { useError } from '../../../contexts/ErrorContext';

interface SafeFormProps {
  formData: SafeFormData;
  handleChange: (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | { name: string; value: any }
  ) => void;
  handleSubmit: (e: React.FormEvent) => void;
  loading: boolean;
}

interface SafeFormData {
  // Recipient Information
  name: string;
  email: string;
  accountAddress: string;
  stakeholderType: string;
  relationship: string;
  stateOfResidency: string;
  employeeID: string;
  jobTitle: string;
  annualSalary: number;

  // Issuance Details
  safeClass: string;
  quantity: number;
  cashPaid: number;
  pricePerShare: number;
  paymentHash: string;
  boardApproval: string;
  issueDate: string;
  rule144Date: string;
  vestingStartDate: string;
  accelerationTerms: string;
  buildLegend: string;
  federalExemption: string;
  stateExemption: string;
  valueOfIPTransferred: number;
  otherConsideration: string;
  rule144DifferenceReason: string;

  // Vesting Data
  moduleData: ModuleData | null;

  // Additional Information
  file: File | null;
  notes: string;
  startDate: string;
  cliffDuration: string;
  vestingDuration: string;
  vestingInterval: string;
  eventIds: string[];
  vestedAmounts: string[];

  // SAFE Details
  discount: number;
  valuationCap: number;
  mostFavoredNation: boolean;
  currency: string;
}

const relationshipOptions = [
  'Investor',
  'Founder',
  'Advisor',
  'Ex-Advisor',
  'Board member',
  'Consultant',
  'Ex-Consultant',
  'Employee',
  'Ex-Employee',
  'Executive',
  'International Employee',
  'Officer',
  'Other',
];

const accelerationTerms = [
  'No acceleration',
  // Add other options if necessary
];

const federalExemptions = [
  'Rule 701',
  'Section 4(a)(2)',
  'Section 4(a)(1-1/2)',
  'Section 4(a)(7)',
  'Rule 144',
  'Reg D - 506(b)',
  'Reg D - 506(c)',
  'Reg D - 506',
  'Reg D - 505',
  'Reg D - 504',
  'Reg S',
  'Reg A (Tier 1)',
  'Reg A (Tier 2)',
  'Reg CF',
  'Non-U.S.',
  'Other',
];

const currencyOptions = [
  'USD',
  'AUD',
  'ARS',
  'BRL',
  'BGN',
  'CAD',
  'CLP',
  'CNY',
  'COP',
  'HRK',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'IDR',
  'INR',
  'ISK',
  'JPY',
  'KES',
  'KRW',
  'MXN',
  'MYR',
  'MUR',
  'NOK',
  'ILS',
  'RON',
  'ANG',
  'NZD',
  'PHP',
  'PLN',
  'GBP',
  'RUB',
  'SGD',
  'ZAR',
  'SAR',
  'SEK',
  'CHF',
  'THB',
  'VND',
];

const SafeForm: React.FC<SafeFormProps> = ({
  formData,
  handleChange,
  handleSubmit,
  loading,
}) => {
  const { auth } = useAuth();
  const { entity } = useEntity();
  const [safeClasses, setSafeClasses] = useState<any[]>([]);
  const [hasVesting, setHasVesting] = useState(false);
  const publicClient = usePublicClient();
  const { address: account } = useAccount();
  const chainId = useChainId();
  const [tokenRegistryStorage] = useState<string | null>(
    contracts[chainId!].IdentityRegistryStorage.address
  );
  const { writeContractsAsync: deploySafeContract } = useWriteContracts();
  const [writeContractsHash, setWriteContractsHash] = useState<any>(null);
  const { setError } = useError();
  const { data: result, refetch } = useCallsStatus({
    id: writeContractsHash || '',
  });
  const [safeContractAddress, setSafeContractAddress] = useState<
    string | undefined | null
  >(undefined);

  const capabilities = React.useMemo(
    () => ({
      paymasterService: {
        url: process.env.REACT_APP_PAYMASTER_URL,
      },
    }),
    []
  );

  // Fetch safe classes
  useEffect(() => {
    const fetchSafeClasses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/safe_classes`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const result = response.data.result;
        setSafeClasses(result);
      } catch (error) {
        console.error('Error fetching share classes:', error);
        // Handle error appropriately
      }
    };

    if (entity?.entity_id) {
      fetchSafeClasses();
    }
  }, [entity]);

  // Fetch existing SAFE contract address from API
  useEffect(() => {
    const fetchSafeContractAddress = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/tokens`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        const safes = response.data.result.filter(
          (token: any) => token.asset_subtype === 'safe'
        );

        if (safes.length > 0) {
          setSafeContractAddress(safes[0].token_address);
        } else {
          setSafeContractAddress(null);
        }
      } catch (error) {
        console.error('Error fetching SAFE contract address:', error);
      }
    };

    fetchSafeContractAddress();
  }, [auth?.token]);

  // Setup polling when txId is set
  useEffect(() => {
    if (!writeContractsHash || result?.status === 'CONFIRMED') return;

    const interval = setInterval(() => {
      refetch();
    }, 1000);

    // Clear interval when component unmounts or writeContractsHash changes
    return () => {
      clearInterval(interval);
    };
  }, [writeContractsHash, refetch]);

  // Deploy SAFE contract if not exists
  useEffect(() => {
    const deploySafe = async () => {
      if (
        safeContractAddress === undefined ||
        safeContractAddress !== null ||
        !tokenRegistryStorage
      )
        return;

      try {
        const params = {
          address: contracts[chainId!].TREXFactory.address as `0x${string}`,
          abi: contracts[chainId!].TREXFactory.abi,
          functionName: 'deployTREXSuite',
          args: [
            new Date().toISOString(), // salt
            {
              name: `${entity.short_name} SAFEs`,
              symbol: 'SAFE',
              uri: '',
              irAgents: [account!],
              tokenAgents: [account!],
              complianceModules: [],
              complianceSettings: [],
              ONCHAINID: zeroAddress,
              owner: account!,
              irs: zeroAddress,
            },
            {
              claimTopics: [],
              issuers: [],
              issuerClaims: [],
            },
            3, // TokenType for SAFE
          ],
        };

        const gasEstimate = await publicClient?.estimateContractGas({
          ...params,
          account,
        });
        console.log('gasEstimate', gasEstimate);

        const result = await deploySafeContract({
          contracts: [params],
          account,
          chainId,
          capabilities,
        });
        setWriteContractsHash(result);
      } catch (error) {
        setError(error);
        console.error('Error deploying SAFE contract:', error);
      }
    };

    if (!safeContractAddress && account && chainId) {
      deploySafe();
    }
  }, [safeContractAddress, account, chainId, tokenRegistryStorage]);

  // Wait for SAFE contract to be deployed
  useEffect(() => {
    if (
      writeContractsHash &&
      result?.status === 'CONFIRMED' &&
      safeContractAddress === null
    ) {
      (async () => {
        try {
          const filteredLogs = result.receipts?.[0]?.logs.filter((l: any) => {
            return (
              l.address.toLowerCase() ===
              contracts[chainId!].TREXFactory.address.toLowerCase()
            );
          });
          const mappedLogs = filteredLogs!.map((l: any) =>
            decodeEventLog({
              abi: contracts[chainId!].TREXFactory.abi as Abi,
              data: l.data,
              topics: l.topics,
            })
          );

          const event: any = mappedLogs?.filter(
            (l: any) => l.eventName === 'TREXSuiteDeployed'
          )[0];

          if (!event) {
            console.error('TREXSuiteDeployed event not found.');
            return;
          }

          const deployedAddress = event.args._token;
          const identityRegistryAddress = event.args._ir;

          setSafeContractAddress(deployedAddress);

          // Save the SAFE contract address to backend
          await axios.post(
            `${process.env.REACT_APP_API_URL}/v1/tokens`,
            {
              asset_subtype: 'safe',
              contract_address: deployedAddress,
              registry_address: identityRegistryAddress,
              identity_address: null,
            },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );

          alert('Successfully deployed SAFE contract');
        } catch (error) {
          console.error('Error tokenizing:', error);
        }
      })();
    }
  }, [result]);

  // Handle module data change from ModuleDataForm
  const handleModuleDataChange = (data: ModuleData) => {
    handleChange({ name: 'moduleData', value: data });
  };

  // Calculate price per share whenever quantity or cashPaid changes
  useEffect(() => {
    const amount = parseFloat(formData.quantity?.toString());
    const cash = parseFloat(formData.cashPaid?.toString());
    if (amount > 0 && cash > 0) {
      const calculatedPrice = cash / amount;
      handleChange({
        target: { name: 'pricePerShare', value: calculatedPrice.toString() },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleChange({
        target: { name: 'pricePerShare', value: '' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [formData.quantity, formData.cashPaid]);

  return (
    <Form onSubmit={handleSubmit}>
      {/* Recipient Information */}
      <h4>Recipient Information</h4>

      <Form.Group controlId="name" className="mb-3">
        <Form.Label>
          Name <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={formData.name}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Email */}
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>
          Email <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          required
          value={formData.email}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Account Address */}
      <Form.Group controlId="accountAddress" className="mb-3">
        <Form.Label>
          Account Address <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="accountAddress"
          value={formData.accountAddress}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Stakeholder Type */}
      <Form.Group controlId="stakeholderType" className="mb-3">
        <Form.Label>
          Stakeholder Type <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="stakeholderType"
          required
          value={formData.stakeholderType}
          onChange={handleChange}
        >
          <option value="">Select Stakeholder Type</option>
          <option value="Individual">Individual</option>
          <option value="Non-individual">Non-individual</option>
        </Form.Select>
      </Form.Group>

      {/* Relationship */}
      <Form.Group controlId="relationship" className="mb-3">
        <Form.Label>
          Relationship <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="relationship"
          required
          value={formData.relationship}
          onChange={handleChange}
        >
          <option value="">Select Relationship</option>
          {relationshipOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* State of Residency */}
      <Form.Group controlId="stateOfResidency" className="mb-3">
        <Form.Label>State of Residency</Form.Label>
        <Form.Control
          type="text"
          name="stateOfResidency"
          value={formData.stateOfResidency}
          onChange={handleChange}
        />
      </Form.Group>

      {formData.relationship === 'Employee' && (
        <>
          {/* Employee ID */}
          <Form.Group controlId="employeeID" className="mb-3">
            <Form.Label>Employee ID</Form.Label>
            <Form.Control
              type="text"
              name="employeeID"
              value={formData.employeeID}
              onChange={handleChange}
            />
          </Form.Group>

          {/* Job Title */}
          <Form.Group controlId="jobTitle" className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </Form.Group>

          {/* Annual Salary */}
          <Form.Group controlId="annualSalary" className="mb-3">
            <Form.Label>Annual Salary</Form.Label>
            <Form.Control
              type="number"
              name="annualSalary"
              value={formData.annualSalary}
              onChange={handleChange}
            />
          </Form.Group>
        </>
      )}

      {/* Issuance Details */}
      <h4 className="mt-4">Issuance Details</h4>

      {/* Safe Class */}
      <Form.Group controlId="safeClass" className="mb-3">
        <Form.Label>
          Safe Class <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="safeClass"
          required
          value={formData.safeClass}
          onChange={handleChange}
        >
          <option value="">Select Safe Class</option>
          <option value="New">New</option>
          {safeClasses.map((safeClass) => (
            <option
              key={safeClass.token_class_id}
              value={safeClass.token_class_id}
            >
              {safeClass.class_name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Cash Paid */}
      <Form.Group controlId="cashPaid" className="mb-3">
        <Form.Label>Cash Paid</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="cashPaid"
            value={formData.cashPaid !== undefined ? formData.cashPaid : ''}
            onChange={handleChange}
          />
        </InputGroup>
      </Form.Group>

      {/* Payment Hash */}
      <Form.Group controlId="paymentHash" className="mb-3">
        <Form.Label>Payment Hash</Form.Label>
        <Form.Control
          type="text"
          name="paymentHash"
          value={formData.paymentHash || ''}
          onChange={handleChange}
        />
        <Form.Text className="text-muted">
          Transaction hash of the payment
        </Form.Text>
      </Form.Group>

      {/* Board Approval */}
      <Form.Group controlId="boardApproval" className="mb-3">
        <Form.Label>
          Board Approval <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="boardApproval"
          required
          value={formData.boardApproval || ''}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Issue Date */}
      <Form.Group controlId="issueDate" className="mb-3">
        <Form.Label>
          Issue Date <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="issueDate"
          required
          value={formData.issueDate || ''}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Include Vesting Conditions */}
      <Form.Group className="mb-3">
        <Form.Check
          type="switch"
          id="vesting-switch"
          label="Include Vesting Conditions"
          checked={hasVesting}
          onChange={(e) => setHasVesting(e.target.checked)}
          disabled
        />
      </Form.Group>

      {/* Vesting Schedule */}
      {hasVesting && (
        <ModuleDataForm
          amount={formData.quantity}
          initialValues={formData.moduleData}
          onChange={handleModuleDataChange}
        />
      )}

      {/* Build Legend */}
      <Form.Group controlId="buildLegend" className="mb-3">
        <Form.Label>Build Legend</Form.Label>
        <Form.Select
          name="buildLegend"
          value={formData.buildLegend || ''}
          onChange={handleChange}
        >
          <option value="Default">Default</option>
          <option value="RSAs legends">RSAs legends</option>
          <option value="Exercise legends">Exercise legends</option>
        </Form.Select>
      </Form.Group>

      {/* Federal Exemption */}
      <Form.Group controlId="federalExemption" className="mb-3">
        <Form.Label>
          Federal Exemption <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="federalExemption"
          required
          value={formData.federalExemption || ''}
          onChange={handleChange}
        >
          <option value="">Select Federal Exemption</option>
          {federalExemptions.map((exemption, index) => (
            <option key={index} value={exemption}>
              {exemption}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* State Exemption */}
      <Form.Group controlId="stateExemption" className="mb-3">
        <Form.Label>State Exemption</Form.Label>
        <Form.Control
          type="text"
          name="stateExemption"
          value={formData.stateExemption || ''}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Value of IP Transferred */}
      <Form.Group controlId="valueOfIPTransferred" className="mb-3">
        <Form.Label>Value of IP Transferred</Form.Label>
        <Form.Control
          type="number"
          name="valueOfIPTransferred"
          value={
            formData.valueOfIPTransferred !== undefined
              ? formData.valueOfIPTransferred
              : ''
          }
          onChange={handleChange}
        />
      </Form.Group>

      {/* Other Consideration */}
      <Form.Group controlId="otherConsideration" className="mb-3">
        <Form.Label>Other Consideration</Form.Label>
        <Form.Control
          type="text"
          name="otherConsideration"
          value={
            formData.otherConsideration !== undefined
              ? formData.otherConsideration
              : ''
          }
          onChange={handleChange}
        />
      </Form.Group>

      {/* Rule 144 Difference Reason */}
      <Form.Group controlId="rule144DifferenceReason" className="mb-3">
        <Form.Label>Rule 144 Difference Reason</Form.Label>
        <Form.Control
          type="text"
          name="rule144DifferenceReason"
          value={
            formData.rule144DifferenceReason !== undefined
              ? formData.rule144DifferenceReason
              : ''
          }
          onChange={handleChange}
        />
      </Form.Group>

      {/* Documents */}
      <Form.Group controlId="file" className="mb-3">
        <Form.Label>Documents</Form.Label>
        <Form.Control type="file" name="file" onChange={handleChange} />
      </Form.Group>

      {/* Notes */}
      <Form.Group controlId="notes" className="mb-3">
        <Form.Label>Notes</Form.Label>
        <Form.Control
          as="textarea"
          name="notes"
          rows={3}
          value={formData.notes !== undefined ? formData.notes : ''}
          onChange={handleChange}
        />
      </Form.Group>

      {/* SAFE Details */}
      {formData.safeClass === 'New' && (
        <>
          <h4 className="mt-4">SAFE Details</h4>

          <Form.Group controlId="discount" className="mb-3">
            <Form.Label>
              Discount (%) <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              name="discount"
              required
              value={formData.discount !== undefined ? formData.discount : ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="valuationCap" className="mb-3">
            <Form.Label>
              Valuation Cap <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              name="valuationCap"
              required
              value={
                formData.valuationCap !== undefined ? formData.valuationCap : ''
              }
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="mostFavoredNation" className="mb-3">
            <Form.Label>Most Favored Nation</Form.Label>
            <Form.Check
              type="checkbox"
              name="mostFavoredNation"
              checked={formData.mostFavoredNation || false}
              onChange={handleChange}
            />
          </Form.Group>
        </>
      )}

      <Form.Group controlId="currency" className="mb-3">
        <Form.Label>
          Investment Token Address <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="currency"
          required
          value={formData.currency || ''}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Submit Button */}
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Submit'}
      </Button>
    </Form>
  );
};

export default SafeForm;
