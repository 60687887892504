// capsign.com/src/config/routeMappings.ts

export const ACCOUNT_TYPE_ROUTES: { [key: string]: string } = {
  individual: '/onboarding/individual/name',
  business_entity: '/onboarding/entity/details',
  non_profit: '/onboarding/non-profit/details',
  educational_institution: '/onboarding/educational-institution/details',
  government_agency: '/onboarding/government-agency/details',
  joint: '/onboarding/joint-account/details',
  fiduciary: '/onboarding/fiduciary/details',
  retirement: '/onboarding/retirement/details',
  charitable: '/onboarding/charitable/details',
};
