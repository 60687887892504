import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';

type AccountContextType = {
  account: {
    account_type: string;
    account_subtype: string;
    account_id: string;
  };
  setAccount: (account: object | null) => void;
};

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};

interface AccountProviderProps {
  children: ReactNode;
}

export const AccountProvider: React.FC<AccountProviderProps> = ({
  children,
}) => {
  const { auth } = useAuth();
  const [account, setAccount] = useState<object>();

  React.useEffect(() => {
    (async () => {
      try {
        if (auth?.token) {
          const response = await axios(
            `${process.env.REACT_APP_API_URL}/v1/accounts/me`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth.user.account_id,
              },
            }
          );
          setAccount(response.data.result);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [auth]);

  const value = { account, setAccount };

  return (
    <AccountContext.Provider value={value as any}>
      {children}
    </AccountContext.Provider>
  );
};
