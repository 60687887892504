import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { contracts } from '../../../constants';
import { NavLink, useNavigate } from 'react-router-dom';
import { useError } from '../../../contexts/ErrorContext';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWriteContract,
  useWalletClient,
} from 'wagmi';
import { useWriteContracts, useCallsStatus } from 'wagmi/experimental';
import { zeroAddress, Abi, parseEventLogs, decodeEventLog } from 'viem';
import { config } from '../../../config';
import ArticlesOfIncorporationUploader from '../../Documents/ArticlesOfIncorporationUploader';
import SidePopout from '../../../components/SidePopout';
import ShareClass from './ShareClass';
import { useEntity } from '../../../contexts/EntityContext';
import OperatingAgreementUploader from '../../Documents/OperatingAgreementUploader';
import InterestClass from './InterestClass';
import {
  type WriteContractsReturnType,
  type WriteContractsVariables,
} from '@wagmi/core/experimental';

interface TokenDetails {
  owner: `0x${string}`;
  name: string;
  symbol: string;
  uri: string;
  irs: `0x${string}`;
  ONCHAINID: `0x${string}`;
  irAgents: `0x${string}`[];
  tokenAgents: `0x${string}`[];
  complianceModules: `0x${string}`[];
  complianceSettings: `0x${string}`[];
}

interface ClaimDetails {
  claimTopics: bigint[];
  issuers: `0x${string}`[];
  issuerClaims: bigint[][];
}

export default function CreateClasses() {
  const { auth } = useAuth();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const { entity } = useEntity();
  const chainId = useChainId();
  const account = useAccount();
  const navigate = useNavigate();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient({ config });
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [validShareClasses, setValidShareClasses] = useState<boolean[]>([]);
  const [tokenType, setTokenType] = useState<'equity' | 'utility'>('equity');
  const [tokenAddress, setTokenAddress] = useState<string>('');
  const [interestClasses, setInterestClasses] = useState<any[]>([]);
  const [validInterestClasses, setValidInterestClasses] = useState<boolean[]>(
    []
  );
  const { writeContractAsync: deployERC3643 } = useWriteContract();
  const [writeContractsHash, setWriteContractsHash] = useState<string | null>(
    null
  );
  const { data: result, refetch } = useCallsStatus({
    id: writeContractsHash || '',
  });

  const capabilities = React.useMemo(
    () => ({
      paymasterService: {
        url: process.env.REACT_APP_PAYMASTER_URL,
      },
    }),
    []
  );

  const { writeContractsAsync: deployContracts, data: deployResults } =
    useWriteContracts({
      mutation: {
        onSuccess: (
          data: WriteContractsReturnType,
          // variables: WriteContractsVariables,
          variables: any,
          context?: any
        ) => {
          setWriteContractsHash(data);
        },
      },
    });

  // Setup polling when txId is set
  useEffect(() => {
    if (!writeContractsHash) return;

    const interval = setInterval(() => {
      refetch();
    }, 1000);

    // Clear interval when component unmounts or writeContractsHash changes
    return () => {
      clearInterval(interval);
    };
  }, [writeContractsHash, refetch]);

  useEffect(() => {
    if (result && result?.status === 'CONFIRMED') {
      (async () => {
        try {
          const filteredLogs = result.receipts?.[0]?.logs.filter(
            (l: any) =>
              l.address ===
              contracts[chainId!].TREXFactory.address.toLowerCase()
          );
          const mappedLogs = filteredLogs!.map((l: any) =>
            decodeEventLog({
              abi: contracts[chainId!].TREXFactory.abi as Abi,
              data: l.data,
              topics: l.topics,
            })
          );

          const event: any = mappedLogs?.filter(
            (l: any) => l.eventName === 'TREXSuiteDeployed'
          )[0];

          if (!event) {
            console.error('TREXSuiteDeployed event not found.');
            return;
          }

          const { _token: tokenAddress, _ir: ir } = event.args as any;

          // Tokenize
          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/share_classes/${shareClasses[0].class_id}/tokenize`,
            method: 'POST',
            data: {
              contract_address: tokenAddress,
              registry_address: ir,
              identity_address: zeroAddress,
              token_symbol: shareClasses[0].prefix,
            },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });

          navigate('/equity');
        } catch (error) {
          console.error('Error tokenizing:', error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [result]);

  /**
   * Fetch share classes
   */
  useEffect(() => {
    const fetchShareClasses = async () => {
      try {
        if (entity?.account_subtype === 'c_corporation') {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/share_classes`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
          setShareClasses(response.data.result);
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/interest_classes`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
            }
          );
          setInterestClasses(response.data.result);
        }
      } catch (error) {
        console.error('Error fetching share classes:', error);
        // Handle error appropriately
      }
    };

    if (entity?.entity_id) {
      fetchShareClasses();
    }
  }, [entity]);

  const handleUploadSuccess = (data: any) => {
    if (entity.account_subtype === 'c_corporation') {
      setShareClasses(data);
    } else {
      setInterestClasses(data);
    }
  };

  const handleShareClassChange = (
    index: number,
    data: any,
    isValid: boolean
  ) => {
    setShareClasses((prevShareClasses) => {
      const updatedShareClasses = [...prevShareClasses];
      updatedShareClasses[index] = data;
      return updatedShareClasses;
    });

    setValidShareClasses((prevValidations) => {
      const updatedValidations = [...prevValidations];
      updatedValidations[index] = isValid;
      return updatedValidations;
    });
  };

  const handleInterestClassChange = (
    index: number,
    data: any,
    isValid: boolean
  ) => {
    setInterestClasses((prevInterestClasses) => {
      const updatedInterestClasses = [...prevInterestClasses];

      // Merge existing class data with new data
      const updatedData = {
        ...prevInterestClasses[index],
        ...data,
      };
      updatedInterestClasses[index] = updatedData;
      return updatedInterestClasses;
    });

    setValidInterestClasses((prevValidations: any[]) => {
      const updatedValidations = [...prevValidations];
      updatedValidations[index] = isValid;
      return updatedValidations;
    });
  };

  const isFormValid = () => {
    return (
      shareClasses.length > 0 && validShareClasses.every((isValid) => isValid)
    );
    // Add validation for other asset types if necessary
    return true;
  };

  /**
   * @function handleSubmitToken
   * @param event
   * @returns
   */
  const handleSubmitToken = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    if (entity.account_subtype === 'c_corporation') {
      for (const shareClass of shareClasses) {
        if (shareClass.contract_address) continue;

        const salt = new Date().toISOString();
        const tokenDetails: TokenDetails = {
          name: shareClasses[0].class_name,
          symbol: shareClasses[0].prefix,
          uri: '',
          irAgents: [account?.address! as `0x${string}`],
          tokenAgents: [account?.address! as `0x${string}`],
          complianceModules: [
            contracts[chainId!].VestingModule.address as `0x${string}`,
          ],
          complianceSettings: [],
          ONCHAINID: zeroAddress as `0x${string}`,
          owner: account?.address! as `0x${string}`,
          irs: zeroAddress as `0x${string}`,
        };

        const claimDetails: ClaimDetails = {
          claimTopics: [],
          issuers: [],
          issuerClaims: [],
        };

        try {
          const tokenType = 0; // TokenType.Stock = 0
          const args = [salt, tokenDetails, claimDetails, tokenType];
          const deployResults = await deployContracts({
            contracts: [
              {
                address: contracts[chainId].TREXFactory
                  .address as `0x${string}`,
                abi: contracts[chainId].TREXFactory.abi as Abi,
                functionName: 'deployTREXSuite',
                args,
              },
            ],
            capabilities,
            account: walletClient?.account,
            chainId,
          });
          setWriteContractsHash(deployResults);
        } catch (error: any) {
          setLoading(false);
          return setError(error);
        }
      }
    } else if (
      entity.account_subtype === 'llc' ||
      entity.account_subtype === 'trust'
    ) {
      for (const interestClass of interestClasses) {
        if (interestClass.contract_address) continue;

        try {
          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/interest_classes/${interestClass.class_id}`,
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
            data: {
              class_id: interestClass.class_id,
              entity_id: entity.entity_id,
              class_name: interestClass.class_name,
              prefix: interestClass.prefix,
              voting_rights: interestClass.voting_rights,
              distribution_priority: interestClass.distribution_priority,
              metadata: interestClass.metadata,
            },
          });
        } catch (error: any) {
          return setError(error);
        }

        const tokenDetails: TokenDetails = {
          owner: account?.address! as `0x${string}`,
          name: interestClass.class_name,
          symbol: interestClass.prefix,
          uri: '',
          irs: zeroAddress as `0x${string}`,
          ONCHAINID: zeroAddress as `0x${string}`,
          irAgents: [account?.address! as `0x${string}`],
          tokenAgents: [account?.address! as `0x${string}`],
          complianceModules: [],
          complianceSettings: [],
        };

        const claimDetails: ClaimDetails = {
          claimTopics: [],
          issuers: [],
          issuerClaims: [],
        };

        try {
          const GATEWAY_ADDRESS = contracts[chainId].FundGateway
            ?.address as `0x${string}`;

          // Check deployment fee enabled
          const deploymentFeeEnabled = await publicClient?.readContract({
            address: GATEWAY_ADDRESS,
            abi: contracts[chainId].FundGateway.abi as Abi,
            functionName: 'isDeploymentFeeEnabled',
            args: [],
          });

          // Check deployment fee
          const deploymentFee = (await publicClient?.readContract({
            address: GATEWAY_ADDRESS,
            abi: contracts[chainId].FundGateway.abi as Abi,
            functionName: 'getDeploymentFee',
            args: [],
          })) as {
            fee: bigint;
            feeToken: `0x${string}`;
            feeCollector: `0x${string}`;
          };

          // Check public deployment status
          const publicDeploymentStatus = await publicClient?.readContract({
            address: GATEWAY_ADDRESS,
            abi: contracts[chainId].FundGateway.abi as Abi,
            functionName: 'getPublicDeploymentStatus',
            args: [],
          });

          // Deploy the token
          const args = [tokenDetails, claimDetails];
          const gasPrice = await publicClient?.getGasPrice();
          const gas = await publicClient?.estimateContractGas({
            address: GATEWAY_ADDRESS,
            abi: contracts[chainId].FundGateway.abi as Abi,
            functionName: 'deployFundSuite',
            args,
            account: account.address,
            value: deploymentFee.fee,
            gasPrice,
          });

          const hash = await deployERC3643({
            address: GATEWAY_ADDRESS,
            abi: contracts[chainId].FundGateway.abi as Abi,
            functionName: 'deployFundSuite',
            args,
            gas: BigInt(4_000_000),
            value: deploymentFee.fee,
          });

          const receipt = await publicClient?.waitForTransactionReceipt({
            hash: hash,
          });

          const logs = parseEventLogs({
            abi: contracts[chainId].FundFactory.abi as Abi,
            logs: receipt?.logs!,
          });

          console.log('logs', logs);

          const event = logs.filter(
            (l) => l.eventName === 'FundSuiteDeployed'
          )[0];

          const { _token: tokenAddress, _ir: ir } = event.args as any;

          // Tokenize
          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/interest_classes/${interestClass.class_id}/tokenize`,
            method: 'POST',
            data: {
              contract_address: tokenAddress,
              registry_address: ir,
              identity_address: zeroAddress,
              token_symbol: interestClass.prefix,
            },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
        } catch (error: any) {
          console.log('error', error);
          let _message = error?.message;
          return setError(error);
        }
      }
      navigate('/equity');
      setLoading(false);
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Tokenize
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container className="py-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h5 className="mb-4">Add Token</h5>
            <Form onSubmit={handleSubmitToken}>
              {/* Conditionally render form fields based on tokenType */}
              <Form.Group controlId="formCompanyName" className="mb-3">
                <Form.Label>Issuer Name</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  disabled
                  defaultValue={entity?.legal_name}
                />
              </Form.Group>
              {entity?.account_subtype === 'c_corporation' ? (
                <ArticlesOfIncorporationUploader
                  onUploadSuccess={handleUploadSuccess}
                />
              ) : (
                <OperatingAgreementUploader
                  onUploadSuccess={handleUploadSuccess}
                />
              )}
              {shareClasses.length > 0 && (
                <Tabs
                  activeKey={activeTabKey}
                  onSelect={(k) => setActiveTabKey(Number(k))}
                  id="share-class-tabs"
                  className="mb-2 mt-4"
                >
                  {shareClasses.map((shareClass, index) => (
                    <Tab
                      eventKey={index}
                      title={shareClass.class_name}
                      key={index}
                    >
                      <ShareClass
                        initialState={shareClass}
                        onChange={(data: any, isValid: boolean) =>
                          handleShareClassChange(index, data, isValid)
                        }
                      />
                    </Tab>
                  ))}
                </Tabs>
              )}
              {/* Render the "Create Token(s)" button only if there is at least one share class */}
              {shareClasses.length > 0 && (
                <Button
                  variant="primary"
                  type="submit"
                  className="mb-3"
                  disabled={loading || !isFormValid()}
                >
                  {loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    'Create Token(s)'
                  )}
                </Button>
              )}
              {interestClasses.length > 0 && (
                <>
                  <Tabs
                    activeKey={activeTabKey}
                    onSelect={(k) => setActiveTabKey(Number(k))}
                    id="interest-class-tabs"
                    className="mb-2 mt-4"
                  >
                    {interestClasses.map((interestClass, index) => (
                      <Tab
                        eventKey={index}
                        title={interestClass.class_name}
                        key={index}
                      >
                        <InterestClass
                          initialState={interestClass}
                          onChange={(data: any, isValid: boolean) =>
                            handleInterestClassChange(index, data, isValid)
                          }
                        />
                      </Tab>
                    ))}
                  </Tabs>
                  <Button variant="primary" type="submit" className="mb-3">
                    Create Interest Class(es)
                  </Button>
                </>
              )}
              {tokenType === 'utility' && (
                <>
                  {/* Fields for utility token */}
                  <Form.Group controlId="formTokenName" className="mb-3">
                    <Form.Label>Token Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={tokenAddress}
                      onChange={(e) => setTokenAddress(e.target.value)}
                      required
                    />
                  </Form.Group>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
      <SidePopout />
    </>
  );
}
