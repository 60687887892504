import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useOnboarding } from '../../contexts/OnboardingContext';
import { ACCOUNT_TYPES, ACCOUNT_SUBTYPES } from '../../config/accountTypes';
import { ACCOUNT_TYPE_ROUTES } from '../../config/routeMappings';
import { useError } from '../../contexts/ErrorContext';

export default function AccountType(props: any) {
  const [accountType, setAccountType] = useState<string>();
  const [ownerType, setOwnerType] = useState<string>();
  const [accountSubtype, setAccountSubtype] = useState<string>('');
  const [isRetirementAccount, setIsRetirementAccount] =
    useState<boolean>(false);
  const accountTypesWithSubtypes = [
    'business_entity',
    'fiduciary',
    'non_profit',
    'government_agency',
  ];
  const { auth } = useAuth();
  const { setError } = useError();
  const { onboarding, setOnboarding } = useOnboarding();
  const navigate = useNavigate();

  useEffect(() => {
    if (onboarding?.accountType) {
      setAccountType(onboarding.accountType);
      const selectedAccount = ACCOUNT_TYPES.find(
        (type) => type.value === onboarding.accountType
      );
      if (selectedAccount) {
        setOwnerType(selectedAccount.ownerType);
      }
    }
  }, [onboarding]);

  const onSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior

    const selectedAccount = ACCOUNT_TYPES.find(
      (type) => type.value === (onboarding?.accountType || accountType)
    );

    if (!selectedAccount) {
      throw new Error('Invalid account type selected.');
    }

    // Get the next route from the route mapping
    const nextRoute = ACCOUNT_TYPE_ROUTES[selectedAccount.value];

    if (!nextRoute) {
      throw new Error('No route defined for the selected account type.');
    }

    // If it's already set, continue to next part
    if (onboarding?.accountType) {
      return navigate(nextRoute);
    }

    try {
      const data = {
        account_type: accountType,
        account_subtype:
          (accountType === 'individual' && isRetirementAccount) ||
          accountTypesWithSubtypes.includes(accountType!)
            ? accountSubtype
            : '',
      };
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/accounts/me`,
        data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-ID': auth?.user.account_id,
          },
        }
      );

      // Update onboarding context with selected account details
      setOnboarding({
        ...onboarding,
        accountType: selectedAccount.value,
        accountSubtype:
          (accountType === 'individual' && isRetirementAccount) ||
          accountTypesWithSubtypes.includes(accountType!)
            ? accountSubtype
            : '',
        ownerType: selectedAccount.ownerType,
        ownerId: auth?.user.account_id || '',
        // Ensure other required properties are included as needed
      });

      return navigate(nextRoute);
    } catch (error: any) {
      console.error('Error creating account:', error);
      setError(error);
    }
  };

  return (
    <div className="row">
      <Form className="col-lg-4 offset-lg-4 col-md-12 py-5" onSubmit={onSubmit}>
        <Form.Group controlId="accountTypeSelect">
          <Form.Label>Select Account Type</Form.Label>
          <Form.Select
            disabled={!!onboarding?.accountType}
            value={accountType || onboarding?.accountType || ''}
            onChange={(event: any) => {
              setAccountType(event.target.value);
              const selectedAccount = ACCOUNT_TYPES.find(
                (type) => type.value === event.target.value
              );
              setOwnerType(selectedAccount?.ownerType || '');
              setIsRetirementAccount(false);
              setAccountSubtype('');
            }}
          >
            <option value="">Please select</option>
            {ACCOUNT_TYPES.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {accountType && (
          <Form.Text className="text-muted">
            {
              ACCOUNT_TYPES.find((type) => type.value === accountType)
                ?.description
            }
          </Form.Text>
        )}
        {accountType === 'individual' && (
          <Form.Group controlId="retirementAccountCheck" className="mt-4">
            <Form.Check
              type="checkbox"
              label="Is this a retirement account?"
              checked={isRetirementAccount}
              onChange={(event: any) => {
                setIsRetirementAccount(event.target.checked);
                setAccountSubtype(''); // Reset subtype when changing retirement status
              }}
            />
          </Form.Group>
        )}
        {((accountType === 'individual' && isRetirementAccount) ||
          accountTypesWithSubtypes.includes(accountType!)) && (
          <>
            <Form.Group controlId="accountSubtypeSelect" className="mt-4">
              <Form.Label>
                {accountType === 'individual'
                  ? 'Select IRA Subtype'
                  : 'Select Account Subtype'}
              </Form.Label>
              <Form.Select
                value={accountSubtype}
                onChange={(event: any) => {
                  setAccountSubtype(event.target.value);
                }}
              >
                <option value="">Please select</option>
                {ACCOUNT_SUBTYPES.filter((subtype) => {
                  if (isRetirementAccount) {
                    return (
                      subtype.parentType === accountType &&
                      ['traditional_ira', 'roth_ira', 'sep_ira'].includes(
                        subtype.value
                      )
                    );
                  } else {
                    return (
                      subtype.parentType === accountType &&
                      subtype.value !== 'traditional_ira' &&
                      subtype.value !== 'roth_ira' &&
                      subtype.value !== 'sep_ira'
                    );
                  }
                }).map((subtype) => (
                  <option key={subtype.value} value={subtype.value}>
                    {subtype.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {accountSubtype && (
              <Form.Text className="text-muted">
                {
                  ACCOUNT_SUBTYPES.find(
                    (subtype) => subtype.value === accountSubtype
                  )?.description
                }
              </Form.Text>
            )}
          </>
        )}
        <Button
          variant="primary"
          className="mt-5 w-100"
          type="submit"
          disabled={
            !accountType ||
            (((accountType === 'individual' && isRetirementAccount) ||
              accountTypesWithSubtypes.includes(accountType)) &&
              !accountSubtype)
          }
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}
