import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { useError } from '../../../contexts/ErrorContext';
import {
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from 'react-bootstrap';
import PDFViewer from './PDFViewer';

export default function OfferingDocuments() {
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const { setError } = useError();
  const [documents, setDocuments] = useState<any[]>([]);
  const [documentUris, setDocumentUris] = useState<{ [key: string]: string }>(
    {}
  );
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [documentData, setDocumentData] = useState<Blob | null>(null);
  const [loadingDocument, setLoadingDocument] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Fetch the current offering documents
   */
  useEffect(() => {
    const fetchOfferingDocuments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setDocuments(response.data.result);

        const documentUris: { [key: string]: string } = {};
        response.data.result.forEach((document: any) => {
          documentUris[document.document_type] = document.document_uri;
        });
        setDocumentUris(documentUris);
      } catch (error: any) {
        console.error('Error fetching documents:', error);
        if (error.response) {
          setError(error.response.data.message);
        }
      }
    };

    fetchOfferingDocuments();
  }, [auth, offeringId]);

  const handleViewDocument = async (document: any) => {
    try {
      setLoadingDocument(true);
      // Fetch the document data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents/${document.document_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          responseType: 'arraybuffer',
        }
      );

      // Determine the file type
      const contentType = response.headers['content-type'];

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Set the selected document and its data
      setSelectedDocument({
        ...document,
        contentType,
      });
      setDocumentData(blob);
      setLoadingDocument(false);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching document:', error);
      setError('Failed to load document.');
      setLoadingDocument(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDocument(null);
    setDocumentData(null);
  };

  const renderDocumentViewer = () => {
    if (!selectedDocument || !documentData) return null;

    const { contentType, document_name } = selectedDocument;

    if (contentType === 'application/pdf') {
      return (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="lg"
          centered
          dialogClassName="modal-90w"
          aria-labelledby="document-modal-title"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="document-modal-title">{document_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PDFViewer data={documentData} />
          </Modal.Body>
        </Modal>
      );
    } else if (
      contentType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      contentType === 'application/msword'
    ) {
      // For Word documents, provide a download link in the modal
      const fileURL = URL.createObjectURL(documentData);
      return (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          aria-labelledby="document-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="document-modal-title">{document_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This document format cannot be displayed. Please download to view
              it.
            </p>
            <a href={fileURL} download={document_name}>
              Click here to download and view the document.
            </a>
          </Modal.Body>
        </Modal>
      );
    } else {
      // For other document types
      return (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          aria-labelledby="document-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="document-modal-title">{document_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Cannot display this type of document.</p>
          </Modal.Body>
        </Modal>
      );
    }
  };

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h1>Documents</h1>
          <ListGroup className="mt-3">
            {documents.length > 0 ? (
              documents.map((document) => (
                <ListGroupItem key={document.document_id}>
                  <strong>{document.document_type}:</strong>{' '}
                  {document.document_uri ? (
                    <Button
                      variant="link"
                      onClick={() => handleViewDocument(document)}
                    >
                      View Document
                    </Button>
                  ) : (
                    'Not Available'
                  )}
                </ListGroupItem>
              ))
            ) : (
              <ListGroupItem>No documents available.</ListGroupItem>
            )}
          </ListGroup>

          {renderDocumentViewer()}
        </Col>
      </Row>
    </Container>
  );
}
