import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import ColorModeToggler from './ColorModeToggler';
import { useTheme } from '../../../contexts/ThemeContext';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useAccount } from '../../../contexts/AccountContext';
import { useToast } from '../../../contexts/ToastContext';

// https://github.com/microsoft/TypeScript/issues/49231#issuecomment-1137251612
declare namespace Intl {
  type Key =
    | 'calendar'
    | 'collation'
    | 'currency'
    | 'numberingSystem'
    | 'timeZone'
    | 'unit';

  function supportedValuesOf(input: Key): string[];
}

function General() {
  const { theme, setTheme } = useTheme();
  const { auth } = useAuth();
  const { account } = useAccount();
  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [identityAddress, setIdentityAddress] = useState<string>();
  const [timeZone, setTimeZone] = useState<string>();
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logoUrl, setLogoUrl] = useState<string>('https://placehold.co/50');
  const { setMessage } = useToast();

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    setMessage('Copied to clipboard!');
  };

  /**
   * Fetch corporate logo
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/entities/me/logo`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
            responseType: 'arraybuffer',
          }
        );
        const contentType = response.headers['content-type'];
        const base64Logo = Buffer.from(response.data, 'binary').toString(
          'base64'
        );
        const logoSrc = `data:${contentType};base64,${base64Logo}`;
        console.log(logoSrc);
        setLogoUrl(logoSrc);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    })();
  });

  /**
   * Fetch user info
   */
  useEffect(() => {
    document
      .getElementsByClassName('page-container')[0]
      .setAttribute('data-bs-theme', theme);

    // Get user information
    (async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/users/me`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setEmail(response.data.result?.email);
        if (
          response.data.result?.first_name &&
          response.data.result?.last_name
        ) {
          setName(
            response.data.result?.first_name +
              ' ' +
              response.data.result?.last_name
          );
        }
        if (response.data.result?.legal_name) {
          setName(response.data.result?.legal_name);
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/identities/me`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setIdentityAddress(response.data.result.identity_address);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [theme]);

  /**
   * Fetch user settings
   */
  useEffect(() => {
    // Fetch user settings including the time zone
    (async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/users/me/settings`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setTimeZone(response.data.result?.time_zone);
      } catch (error) {
        console.error('Failed to fetch user settings', error);
      }
    })();
  }, [auth?.token]);

  /**
   * @function handleTimeZoneChange
   * @param newTimeZone
   */
  const handleTimeZoneChange = (newTimeZone: string) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/v1/users/me/settings/time_zone`,
        {
          time_zone: newTimeZone,
        },
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      )
      .then(() => {
        setTimeZone(newTimeZone);
      })
      .catch((error) => {
        console.log('Error updating time zone:', error);
      });
  };

  /**
   * Handle file input change and upload
   */
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setLogoFile(file);

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/entities/me/logo`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'multipart/form-data',
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setLogoUrl(response.data.result);
      } catch (error) {
        console.error('Error uploading logo:', error);
      }
    }
  };

  /**
   * Toggle theme
   */
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <div>
          <h4 className="fw-semibold mb-1 mt-4">General</h4>
          <p className="text-sm text-muted"></p>
        </div>
        {/* <div className="d-none d-md-flex gap-2">
          <button type="button" className="btn btn-sm btn-secondary">Cancel</button>
          <button type="submit" className="btn btn-sm btn-primary">Save</button>
        </div> */}
      </div>
      <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Your name</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <div className="">
            <input
              type="text"
              className="form-control"
              defaultValue={name}
              readOnly
            />
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Your email</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <div className="">
            <input
              type="text"
              className="form-control"
              defaultValue={email}
              readOnly
            />
          </div>
        </div>
      </div>
      {identityAddress && (
        <>
          <hr className="my-4" />
          <div className="row align-items-center">
            <div className="col-md-2">
              <label className="form-label">Your identity</label>
            </div>
            <div className="col-md-8 col-xl-5">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={identityAddress}
                />
                <button
                  className="btn btn-outline-primary"
                  onClick={() => copyToClipboard(identityAddress)}
                >
                  <i className="fa fa-copy"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Your time zone</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <select
            id="timezone-select"
            className="form-select"
            defaultValue={timeZone}
            value={timeZone}
            onChange={(e) => handleTimeZoneChange(e.target.value)}
          >
            <option>Select a time zone</option>
            {Intl.supportedValuesOf('timeZone').map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Bio</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <div className="">
            <label className="form-label visually-hidden">Bio</label>
            <textarea className="form-control" placeholder="Your email" rows={3}></textarea>
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Username</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <div className="">
            <div className="input-group position-relative">
              <span className="input-group-text">capsign.com/</span>
              <input type="email" className="form-control" placeholder="username" aria-label="username" />
            </div>
            <span className="mt-2 valid-feedback">Looks good!</span>
          </div>
        </div>
      </div> */}

      {account?.account_type === 'business_entity' && (
        <>
          <hr className="my-4" />
          <div className="row align-items-center">
            <div className="col-md-2">
              <label className="form-label">Corporate logo</label>
            </div>
            <div className="col-md-8 col-xl-5">
              <div className="d-flex align-items-center">
                <img
                  src={logoUrl}
                  className="rounded-circle border"
                  width={50}
                />
                <input
                  type="file"
                  className="ms-4 form-control"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Cover</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <div className="">
            <label className="form-label visually-hidden">Cover</label>
            <div className="card shadow-none border-2 border-dashed border-primary-hover position-relative">
              <div className="d-flex justify-content-center px-5 py-5">
                <label htmlFor="cover_upload" className="position-absolute w-100 h-100 top-0 start-0 cursor-pointer">
                  <input id="cover_upload" name="cover_upload" type="file" className="visually-hidden" />
                </label>
                <div className="text-center">
                  <div className="text-2xl text-muted">
                    <i className="bi bi-upload"></i>
                  </div>
                  <div className="d-flex text-sm mt-3">
                    <p className="fw-semibold">Upload a file or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 3MB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Privacy</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" name="switch_make_public" id="switch_make_public" />
            <label className="form-check-label ms-2" htmlFor="switch_make_public">
              Make my profile public
            </label>
          </div>
        </div>
      </div> */}
      <hr className="my-4" />
      <div className="row align-items-center">
        <div className="col-md-2">
          <label className="form-label">Theme</label>
        </div>
        <div className="col-md-8 col-xl-5">
          <ColorModeToggler toggleTheme={toggleTheme} />
        </div>
      </div>
      <hr className="my-4 d-md-none" />

      <div className="d-flex d-md-none justify-content-end gap-2 mb-6">
        <button type="button" className="btn btn-sm btn-neutral">
          Cancel
        </button>
        <button type="submit" className="btn btn-sm btn-primary">
          Save
        </button>
      </div>
    </>
  );
}

export default General;
