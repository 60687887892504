// src/pages/Offerings/EditOffering/EditOfferingSafeClass.tsx

import React, { useState, useEffect } from 'react';
import {
  useAccount,
  useChainId,
  usePublicClient,
  useWalletClient,
  useWriteContract,
} from 'wagmi';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import SAFE from '@capsign/contracts/artifacts/src/securities/token/SAFE.sol/SAFE.json';
import { useOffering } from '../../../contexts/OfferingContext';
import { useError } from '../../../contexts/ErrorContext';
import usdcImage from '../../../assets/images/usdc.png';
import ethImage from '../../../assets/images/eth.png';
import TokenInputDropdown from '../CreateOffering/TokenInputDropdown';
import { Abi, parseEventLogs, parseUnits, zeroAddress } from 'viem';
import { contracts } from '../../../constants';
import { useEntity } from '../../../contexts/EntityContext';

const EditOfferingSafeClass: React.FC = () => {
  const { address: account } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const navigate = useNavigate();
  const { offering, fetchOffering } = useOffering();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState<`0x${string}` | undefined>();
  const chainId = useChainId();
  const { entity } = useEntity();
  const [selectedSafeClass, setSelectedSafeClass] = useState<string | null>(
    null
  );

  const {
    data: factoryTxHash,
    error: factoryError,
    writeContractAsync: deployTREXSuite,
  } = useWriteContract();

  const {
    data: createSafeClassHash,
    error: createSafeClassError,
    writeContractAsync: createSafeClass,
  } = useWriteContract();

  const recommendedTokens = [
    {
      address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
      name: 'USD Coin',
      imageUrl: usdcImage,
    },
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ethereum',
      imageUrl: ethImage,
    },
  ];

  const [formData, setFormData] = useState<any>({
    discount: '0',
    valuationCap: '0',
    mostFavoredNation: false,
    currency: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
    minimumInvestment: '',
    investmentDeadline: '',
    boardApprovalDate: '',
    stockholderApprovalDate: '',
    considerationText: '',
    proRataPercentage: '0',
    seniority: '1',
    uri: '',
    selectedSafeClass: '',
  });

  const [contractAddress, setContractAddress] = useState<
    `0x${string}` | undefined
  >();

  const [safeClasses, setSafeClasses] = useState<any[]>([]);

  /**
   * Fetch SAFE contract
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/me/safe_contract`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setContractAddress(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  useEffect(() => {
    // Fetch existing SAFE terms from the backend
    const fetchSafeClass = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        const safeClass = response.data.result?.safe_class;

        setFormData({
          discount: safeClass?.discount || '',
          valuationCap: safeClass?.valuation_cap || '',
          mostFavoredNation: safeClass?.most_favored_nation || false,
          currency: safeClass?.currency || '',
          boardApprovalDate: safeClass?.board_approval_date
            ? new Date(safeClass?.board_approval_date)
                .toISOString()
                .split('T')[0]
            : '',
          stockholderApprovalDate: safeClass?.stockholder_approval_date
            ? new Date(safeClass?.stockholder_approval_date)
                .toISOString()
                .split('T')[0]
            : '',
          considerationText: safeClass?.consideration_text || '',
          proRataPercentage: safeClass?.pro_rata_percentage || 0,
          seniority: safeClass?.seniority || '',
          uri: safeClass?.uri || '',
        });
        // setContractAddress(safeClass.contract_address);
      } catch (error) {
        console.error('Error fetching SAFE class:', error);
        setError('Failed to fetch SAFE class.');
      }
    };

    fetchSafeClass();
  }, [offeringId, auth?.token]);

  useEffect(() => {
    const fetchSafeClasses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/safe_classes`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setSafeClasses(response.data.result);
      } catch (error) {
        console.error('Error fetching SAFE classes:', error);
        setError('Failed to fetch SAFE classes.');
      }
    };

    fetchSafeClasses();
  }, [auth?.token]);

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;

    if (name === 'selectedSafeClass') {
      if (value === '') {
        // Create new SAFE class
        setFormData({
          discount: '',
          valuationCap: '',
          mostFavoredNation: false,
          currency: '',
          minimumInvestment: '',
          investmentDeadline: '',
          boardApprovalDate: '',
          stockholderApprovalDate: '',
          considerationText: '',
          proRataPercentage: '',
          seniority: '',
          uri: '',
          selectedSafeClass: '',
        });
        setSelectedSafeClass('');
      } else {
        // Existing SAFE class selected
        const _selectedClass = safeClasses.find(
          (sc) => sc.class_id.toString() === value
        );
        setSelectedSafeClass(_selectedClass?.class_id);
        if (_selectedClass) {
          setFormData({
            ...formData,
            discount: _selectedClass.discount || '',
            valuationCap: _selectedClass.valuation_cap || '',
            mostFavoredNation: _selectedClass.most_favored_nation || false,
            currency: _selectedClass.currency || '',
            minimumInvestment: _selectedClass.minimum_investment || '',
            investmentDeadline: _selectedClass.investment_deadline
              ? new Date(_selectedClass.investment_deadline)
                  .toISOString()
                  .split('T')[0]
              : '',
            boardApprovalDate: _selectedClass.board_approval_date
              ? new Date(_selectedClass.board_approval_date)
                  .toISOString()
                  .split('T')[0]
              : '',
            stockholderApprovalDate: _selectedClass.stockholder_approval_date
              ? new Date(_selectedClass.stockholder_approval_date)
                  .toISOString()
                  .split('T')[0]
              : '',
            considerationText: _selectedClass.consideration_text || '',
            proRataPercentage: _selectedClass.pro_rata_percentage || '',
            seniority: _selectedClass.seniority || '',
            uri: _selectedClass.uri || '',
            selectedSafeClass: value,
          });
        }
      }
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Fetch the existing safe class ID from the offering
      const existingSafeClassId = offering?.safe_class?.class_id || '';
      console.log({ selectedSafeClass, existingSafeClassId });
      // Check if selected safe class is different from the offering's safe class
      if (selectedSafeClass && selectedSafeClass !== existingSafeClassId) {
        // Associate the selected safe class with the offering
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/offering_safe_class`,
          { class_id: selectedSafeClass },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      } else if (!selectedSafeClass) {
        // Create a new safe class

        // Prepare the safe terms
        const safeTerms = {
          currency: formData.currency,
          discount: parseUnits(formData.discount || '0', 2),
          valuationCap: parseUnits(formData.valuationCap || '0', 2),
          uri: formData.uri || '',
        };

        // Create safe class on the smart contract
        const safeContract = {
          address: contractAddress as `0x${string}`,
          abi: SAFE.abi,
        };

        const createSafeClassHash = await createSafeClass({
          ...safeContract,
          functionName: 'createSafeClass',
          args: [safeTerms],
          account: account!,
        });

        const createSafeClassReceipt =
          await publicClient?.waitForTransactionReceipt({
            hash: createSafeClassHash,
          });

        // Extract classId from the ClassCreated event
        let classId: bigint | undefined;

        if (createSafeClassReceipt?.logs) {
          for (const log of createSafeClassReceipt.logs) {
            try {
              const event = parseEventLogs({
                abi: SAFE.abi as Abi,
                logs: [log],
              })[0];
              if (event.eventName === 'ClassCreated') {
                classId = (event.args as any).classId;
                break;
              }
            } catch (error) {
              // Not the event we are looking for
            }
          }
        }

        if (!classId) {
          throw new Error('Failed to create SAFE class.');
        }

        // Create the safe class on the backend and associate it with the offering
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_class`,
          {
            discount: formData.discount,
            valuation_cap: formData.valuationCap,
            most_favored_nation: formData.mostFavoredNation,
            currency: formData.currency,
            minimum_investment: formData.minimumInvestment,
            board_approval_date: formData.boardApprovalDate,
            stockholder_approval_date: formData.stockholderApprovalDate,
            consideration_text: formData.considerationText,
            pro_rata_percentage: formData.proRataPercentage,
            investment_deadline: formData.investmentDeadline,
            seniority: formData.seniority,
            uri: formData.uri,
            tx_hash: createSafeClassHash,
            token_class_id: Number(classId),
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      } else {
        // Update the existing safe class
        console.log({ selectedSafeClass });
        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_class`,
          {
            discount: formData.discount,
            valuation_cap: formData.valuationCap,
            most_favored_nation: formData.mostFavoredNation,
            currency: formData.currency,
            minimum_investment: formData.minimumInvestment,
            board_approval_date: formData.boardApprovalDate,
            stockholder_approval_date: formData.stockholderApprovalDate,
            consideration_text: formData.considerationText,
            pro_rata_percentage: formData.proRataPercentage,
            investment_deadline: formData.investmentDeadline,
            seniority: formData.seniority,
            token_class_id: Number(selectedSafeClass),
            uri: formData.uri,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
      }

      // Navigate to next page or show confirmation
      navigate(`/offerings/${offeringId}/review`, {
        state: offering,
      });
    } catch (error: any) {
      console.error('Error updating SAFE terms:', error);
      setError(
        error?.response?.data?.message || 'Failed to update SAFE terms.'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (offeringId && !offering) fetchOffering(offeringId);
  }, [offeringId, fetchOffering, offering]);

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-8 offset-2">
          <h2>Edit SAFE Offering Class</h2>
          <Form onSubmit={handleSubmit} className="mt-5">
            {loading && <p>Updating SAFE terms...</p>}

            {/* Dropdown for selecting existing SAFE class */}
            <Form.Group as={Row} className="mb-3" controlId="formSafeClass">
              <Form.Label column sm={2}>
                Select SAFE Class
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  name="selectedSafeClass"
                  value={selectedSafeClass || formData.selectedSafeClass}
                  onChange={handleFormChange}
                >
                  <option value="">Create New SAFE Class</option>
                  {safeClasses.map((safeClass) => (
                    <option value={safeClass.class_id} key={safeClass.class_id}>
                      {safeClass.class_name ||
                        `SAFE Class ${safeClass.class_id}`}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            {/* Discount */}
            <Form.Group as={Row} className="mb-3" controlId="formDiscount">
              <Form.Label column sm={2}>
                Discount (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="discount"
                  value={formData.discount}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Valuation Cap */}
            <Form.Group as={Row} className="mb-3" controlId="formValuationCap">
              <Form.Label column sm={2}>
                Valuation Cap
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="valuationCap"
                  value={formData.valuationCap}
                  onChange={handleFormChange}
                  required
                />
                <Form.Text className="text-muted">
                  Enter 0 if there is no valuation cap.
                </Form.Text>
              </Col>
            </Form.Group>

            {/* Most Favored Nation */}
            <Form.Group as={Row} className="mb-3" controlId="formMFN">
              <Form.Label column sm={2}>
                Most Favored Nation
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="checkbox"
                  name="mostFavoredNation"
                  checked={formData.mostFavoredNation}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Investment Token */}
            <Form.Group as={Row} className="mb-3" controlId="formCurrency">
              <Form.Label column sm={2}>
                Investment Token Address
              </Form.Label>
              <Col sm={10}>
                <TokenInputDropdown
                  recommendedTokens={recommendedTokens}
                  value={formData.currency}
                  onChange={(value) =>
                    setFormData({ ...formData, currency: value })
                  }
                />
              </Col>
            </Form.Group>

            {/* Board Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formBoardApprovalDate"
            >
              <Form.Label column sm={2}>
                Board Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="boardApprovalDate"
                  value={formData.boardApprovalDate}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Stockholder Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formStockholderApprovalDate"
            >
              <Form.Label column sm={2}>
                Stockholder Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="stockholderApprovalDate"
                  value={formData.stockholderApprovalDate}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Consideration Text */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formConsiderationText"
            >
              <Form.Label column sm={2}>
                Consideration Text
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  name="considerationText"
                  rows={3}
                  placeholder="Describe the consideration for the SAFE"
                  value={formData.considerationText}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Pro Rata Percentage */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formProRataPercentage"
            >
              <Form.Label column sm={2}>
                Pro Rata Rights (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="proRataPercentage"
                  value={formData.proRataPercentage}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Seniority */}
            <Form.Group as={Row} className="mb-3" controlId="formSeniority">
              <Form.Label column sm={2}>
                Seniority
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="seniority"
                  value={formData.seniority}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* URI */}
            <Form.Group as={Row} className="mb-3" controlId="formURI">
              <Form.Label column sm={2}>
                Metadata URI
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="uri"
                  placeholder="Enter metadata URI"
                  value={formData.uri}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Save Changes'}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default EditOfferingSafeClass;
