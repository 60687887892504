import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useEntity } from '../../contexts/EntityContext';
import {
  useChainId,
  useAccount,
  usePublicClient,
  useWalletClient,
} from 'wagmi';
import SAFE from '@capsign/contracts/artifacts/src/securities/token/SAFE.sol/SAFE.json';
import { zeroAddress } from 'viem';
import { contracts } from '../../constants';

export default function ViewSafeClasses() {
  const { auth } = useAuth();
  const { entity } = useEntity();
  const chainId = useChainId();
  const [safeClasses, setSafeClasses] = useState<any[]>([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [formData, setFormData] = useState({
    className: '',
    discount: 0,
    valuationCap: 0,
    currency: '',
    uri: '',
  });

  const { address: account } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const [safeContractAddress, setSafeContractAddress] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    const address = contracts[chainId]?.SAFE?.address;
    setSafeContractAddress(address);
  }, [chainId]);

  /**
   * Fetch SAFE contract
   */
  useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/me/safe_contract`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setSafeContractAddress(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  /**
   * Fetch safe classes
   */
  useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/safe_classes`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setSafeClasses(result);
          if (result.length === 0) setShowCreateForm(true);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, entity.entity_id, chainId]);

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = event.target;

    setFormData({
      ...formData,
      [name]: type === 'number' ? Number(value) : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      // On-chain creation of safe class
      const safeContract = {
        address: safeContractAddress as `0x${string}`,
        abi: SAFE.abi,
      };

      const terms = {
        currency: formData.currency || zeroAddress,
        discount: BigInt(formData.discount),
        valuationCap: BigInt(formData.valuationCap),
        uri: formData.uri || '',
      };

      // Check if the safe class already exists on-chain
      const classId = await publicClient?.readContract({
        ...safeContract,
        functionName: 'getSafeClassId',
        args: [terms],
      });

      if (classId === BigInt(0)) {
        // Create new safe class on-chain
        const gas = await publicClient?.estimateContractGas({
          ...safeContract,
          functionName: 'createSafeClass',
          args: [terms],
          account: account!,
        });

        const createSafeClassHash = await walletClient?.writeContract({
          ...safeContract,
          functionName: 'createSafeClass',
          args: [terms],
          account: account!,
          gas,
        });

        await publicClient?.waitForTransactionReceipt({
          hash: createSafeClassHash!,
        });
      }

      // Call backend API to create new safe class
      const url = `${process.env.REACT_APP_API_URL}/v1/entities/${entity.entity_id}/safe_classes`;
      const response = await axios.post(
        url,
        {
          class_name: formData.className,
          discount: formData.discount,
          valuation_cap: formData.valuationCap,
          currency: formData.currency,
          uri: formData.uri,
          token_address: safeContractAddress,
          token_class_id: Number(classId),
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Update the safeClasses state with the new safe class
      setSafeClasses([...safeClasses, response.data.result]);
      setShowCreateForm(false);

      // Clear form data
      setFormData({
        className: '',
        discount: 0,
        valuationCap: 0,
        currency: '',
        uri: '',
      });
    } catch (error: any) {
      console.error('Error creating safe class on-chain:', error);
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/safes">Safes</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Classes
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Row>
        <Col md={{ span: 10, offset: 1 }} className="mb-3">
          {safeClasses.length > 0 && (
            <>
              <div className="d-flex justify-content-between align-items-center mb-3 mt-5">
                <h2>Your Safe Classes</h2>
                <Button
                  variant="primary"
                  onClick={() => setShowCreateForm(true)}
                >
                  Create New Safe Class
                </Button>
              </div>
              {safeClasses.map((safeClass: any, index: number) => (
                <NavLink
                  to={`/safes/classes/${safeClass.class_id}`}
                  className="text-decoration-none"
                  key={index}
                  state={{
                    safeClass,
                    entity,
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center border rounded shadow-sm p-4 my-3">
                    <div className="text-truncate">
                      <span className="text-dark">{safeClass.class_name}</span>
                      <br />
                    </div>
                    {/* TODO: Link to explorer */}
                    <div className="d-none d-md-block">
                      {safeClass.token_address}
                    </div>
                  </div>
                </NavLink>
              ))}
            </>
          )}
          {showCreateForm && (
            <Form onSubmit={handleSubmit} className="mt-4">
              <h3>Create a New Safe Class</h3>
              <Form.Group controlId="formClassName" className="mb-3">
                <Form.Label>Class Name</Form.Label>
                <Form.Control
                  type="text"
                  name="className"
                  value={formData.className}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formDiscount" className="mb-3">
                <Form.Label>Discount (%)</Form.Label>
                <Form.Control
                  type="number"
                  name="discount"
                  value={formData.discount}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group controlId="formValuationCap" className="mb-3">
                <Form.Label>Valuation Cap</Form.Label>
                <Form.Control
                  type="number"
                  name="valuationCap"
                  value={formData.valuationCap}
                  onChange={handleFormChange}
                />
                <Form.Text className="text-muted">
                  Enter 0 if there is no valuation cap.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formCurrency" className="mb-3">
                <Form.Label>Currency Address</Form.Label>
                <Form.Control
                  type="text"
                  name="currency"
                  value={formData.currency}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group controlId="formURI" className="mb-3">
                <Form.Label>Metadata URI</Form.Label>
                <Form.Control
                  type="text"
                  name="uri"
                  value={formData.uri}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <div className="d-flex">
                <Button variant="primary" type="submit">
                  Create Safe Class
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateForm(false)}
                  className="ms-2"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
}
