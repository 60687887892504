import React, { useEffect, useState } from 'react';
import { Container, Accordion, Card } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';
import { useParams } from 'react-router-dom';

const regCFQuestions = [
  {
    question: 'What is Regulation Crowdfunding (Reg CF)?',
    answer:
      'Regulation Crowdfunding is a set of rules adopted by the SEC that allow private companies to raise capital from the general public through online crowdfunding platforms. It aims to democratize access to investment opportunities by enabling individuals to invest in startups and small businesses.',
  },
  {
    question: 'Who can invest in a Reg CF offering?',
    answer:
      "Both accredited and non-accredited investors can invest in a Reg CF offering. However, there are investment limits based on an individual's income and net worth to protect non-accredited investors from excessive risk.",
  },
  {
    question: 'What is the minimum and maximum investment amount?',
    answer:
      "The minimum investment amount for CapSign's offering is $500 (500 shares), and the maximum investment amount is $300,000 (300,000 shares).",
  },
  {
    question: 'What is the share price for CapSign’s Reg CF offering?',
    answer: 'The share price for CapSign’s Reg CF offering is $1.00 per share.',
  },
  {
    question: 'What are the key dates for the offering?',
    answer:
      'The offering period starts on July 30, 2024, and ends on September 30, 2024.',
  },
  {
    question: "How can I invest in CapSign's Reg CF offering?",
    answer:
      "Investors can participate in the offering through CapSign's funding portal. You will need to create an account, complete the necessary verification processes, and follow the instructions to make an investment.",
  },
  {
    question:
      'What are the risks associated with investing in a Reg CF offering?',
    answer:
      'Investing in early-stage companies involves significant risks, including the potential loss of your entire investment. These investments are illiquid, meaning they cannot be easily sold or exchanged for cash. It’s important to carefully review all offering materials and consider your risk tolerance before investing.',
  },
  {
    question: 'What will the funds raised be used for?',
    answer:
      "The funds raised from the Reg CF offering will be used to accelerate the development of CapSign's platform, expand its user base, and enhance its suite of blockchain-enabled financial services.",
  },
  {
    question: 'Is my investment protected or insured?',
    answer:
      'Investments in Reg CF offerings are not protected or insured by the FDIC or any other government agency. You should be prepared to lose your entire investment.',
  },
  {
    question: 'How do I stay updated on the progress of my investment?',
    answer:
      'CapSign will provide updates through its platform. Investors will receive periodic reports on the company’s progress and the use of funds. Additionally, any significant updates or changes will be communicated directly to investors.',
  },
  {
    question: 'Can I sell my shares after investing?',
    answer:
      'Shares purchased in a Reg CF offering are subject to resale restrictions for a period of one year. After this period, you may be able to sell your shares, but there may be limited options for doing so, as private company shares are not traded on public exchanges.',
  },
  {
    question: 'What happens if the company does not reach its funding goal?',
    answer:
      'If CapSign does not reach its funding goal, the offering may be canceled, and any funds invested will be returned to the investors.',
  },
  {
    question: 'How do I get more information about CapSign’s Reg CF offering?',
    answer:
      'Additional information about the offering can be found in the offering statement filed with the SEC, available at www.sec.gov. You can also contact CapSign’s support team for any specific queries or concerns.',
  },
];

type Question = {
  question: string;
  answer: string;
};

const generationsTrustQuestions: Question[] = [
  {
    question: 'What is Generations Trust?',
    answer:
      'Generations Trust is a real estate investment trust (REIT) that invests in income-producing properties in the Monterey Peninsula area, focusing on luxury residential and commercial real estate.',
  },
  {
    question: 'How does Generations Trust generate returns for investors?',
    answer:
      'Generations Trust generates returns through rental income from its properties and long-term property value appreciation under a Core-Plus investment strategy.',
  },
  {
    question: 'What is a Core-Plus investment strategy?',
    answer:
      'A Core-Plus strategy focuses on acquiring stabilized, high-quality properties in prime locations, with the potential for moderate improvements and value appreciation over time.',
  },
  {
    question: 'What types of properties does Generations Trust invest in?',
    answer:
      'Generations Trust Monterey Peninsula primarily invests in luxury residential and commercial real estate in high-demand areas such as Carmel-by-the-Sea, Pebble Beach, and Carmel Valley.',
  },
  {
    question: 'What is the minimum investment amount?',
    answer: 'The minimum investment amount for Generations Trust is $10,000.',
  },
  {
    question: 'How are distributions made to investors?',
    answer:
      'Distributions are made quarterly from the net income generated by the properties, primarily rental income. Additional distributions may be made upon the sale of a property.',
  },
  {
    question: 'Can I choose which properties my investment supports?',
    answer:
      'Generations Trust allows investors to invest in the overall trust, which pools funds for properties in its portfolio. Investors do not select individual properties but benefit from diversification across the portfolio.',
  },
  {
    question: 'What happens if a property underperforms?',
    answer:
      'Generations Trust maintains reserves to cover short-term shortfalls and employs a professional asset management team to optimize property performance and reduce risks.',
  },
  {
    question: 'Are investments in Generations Trust liquid?',
    answer:
      'No, investments in Generations Trust are illiquid. They are meant for long-term investors and are subject to restrictions on transfers or redemptions.',
  },
  {
    question: 'What fees are associated with investing in Generations Trust?',
    answer:
      'Generations Trust charges a 1% annual AUM management fee, and additional fees may apply for property acquisitions and dispositions. Details are outlined in the offering documents.',
  },
  {
    question: 'How do I stay updated on the performance of Generations Trust?',
    answer:
      'Investors will receive regular updates and performance reports through the investor portal. Quarterly financial statements and annual reports will also be provided.',
  },
  {
    question:
      'What are the risks associated with investing in Generations Trust?',
    answer:
      'Investing in real estate carries risks, including market fluctuations, property-specific risks, and economic downturns. Investments are not insured or guaranteed, and investors should be prepared for potential losses.',
  },
];

export default function OfferingQuestions() {
  const [questions, setQuestions] = useState(generationsTrustQuestions);
  const { auth } = useAuth();
  const { setError } = useError();
  const { offeringId } = useParams();
  /**
   * Fetch the current offering questions
   */
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/faqs`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setQuestions(response.data.result);
      } catch (error: any) {
        console.error('Error fetching questions:', error);
        if (error.response) {
          setError(error.response.data.message);
        }
      }
    };

    fetchQuestions();
  }, [auth]);

  return (
    <Container className="mt-3">
      <h1>Frequently Asked Questions</h1>
      <Accordion defaultActiveKey="0" as={Card} className="m-0 p-0">
        {questions.map((question, index) => (
          <Accordion.Item key={index} eventKey={`${index}`} className="m-0 p-0">
            <Accordion.Header as={Card.Header} className="m-0 p-0">
              <span className="fw-bold">{question.question}</span>
            </Accordion.Header>
            <Accordion.Body as={Card.Body}>{question.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
}
