import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';
import StockForm from './StockForm';
import AllocationForm from './InterestForm';
import SafeForm from './SafeForm';
import { useCallsStatus, useWriteContracts } from 'wagmi/experimental';
import { contracts } from '../../../constants';
import { useAccount, useChainId, usePublicClient } from 'wagmi';
import { Abi, decodeEventLog, zeroAddress } from 'viem';

const EditDraft = () => {
  const { draftId } = useParams<{ draftId: string }>();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const { writeContracts: createSafeClass } = useWriteContracts();
  const [writeContractsHash, setWriteContractsHash] = useState<string | null>(
    null
  );
  const { data: result, refetch } = useCallsStatus({
    id: writeContractsHash || '',
  });
  const chainId = useChainId();
  const { address } = useAccount();
  const publicClient = usePublicClient();

  // Contact information
  const [identity, setIdentity] = useState<any>(null);
  const [tokenAddress, setTokenAddress] = useState<any>(null);
  const [transactionHash, setTransactionHash] = useState<any>(null);

  // Share class and cap table state
  const [shareClass, setShareClass] = useState<any>();
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [capTable, setCapTable] = useState<any>();
  const [outstandingShares, setOutstandingShares] = useState<number>();
  const [interestClass, setInterestClass] = useState<any>();
  const [interestClasses, setInterestClasses] = useState<any[]>([]);
  const [safeClass, setSafeClass] = useState<any>();
  const [safeClasses, setSafeClasses] = useState<any[]>([]);

  // State to hold draft type
  const [draftType, setDraftType] = useState<
    'Shares' | 'Unit Interests' | 'SAFE' | undefined
  >(undefined);

  // Fetch share class and cap table
  useEffect(() => {
    (async () => {
      try {
        if (draftType === 'Shares') {
          // Fetch share classes
          const responseShareClasses = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/entities/me/share_classes`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setShareClasses(responseShareClasses.data.result);
          setShareClass(responseShareClasses.data.result[0]);
          setTokenAddress(responseShareClasses.data.result[0]?.token_address);
          // Fetch cap table
          const responseCapTable = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/cap_tables/me`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setCapTable(responseCapTable.data.result);
        } else if (draftType === 'Unit Interests') {
          // Fetch interest class
          const responseInterestClass = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/entities/me/interest_classes`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setInterestClasses(responseInterestClass.data.result);
          setInterestClass(responseInterestClass.data.result[0]);
          setTokenAddress(responseInterestClass.data.result[0].token_address);
        } else if (draftType === 'SAFE') {
          // Fetch safe class
          const responseSafeClass = await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/entities/me/safe_classes`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          setSafeClasses(responseSafeClass.data.result);

          if (responseSafeClass.data.result.length > 0) {
            setSafeClass(responseSafeClass.data.result[0]);
            setTokenAddress(responseSafeClass.data.result[0]?.token_address);
          }
        }
      } catch (error) {
        setError(error);
      }
    })();
  }, [auth?.token, draftType]);

  // Fetch safe terms
  useEffect(() => {
    (async () => {
      try {
        const checkClassId = false;
        if (tokenAddress && checkClassId) {
          console.log('tokenAddress', tokenAddress);
          const classId = 1;
          console.log('classId', classId);
          const safeClass = await publicClient?.readContract({
            address: tokenAddress as `0x${string}`,
            abi: contracts[chainId!].SAFE.abi,
            functionName: 'getSafeTermsByClass',
            args: [classId],
          });
          console.log('class', classId, 'safeClass:', safeClass);
        }
      } catch (error) {
        console.error('Error fetching safe terms:', error);
      }
    })();
  }, [publicClient]);

  // Form state
  const [formData, setFormData] = useState<any>({});

  const [hasVesting, setHasVesting] = useState<boolean>(
    Boolean(formData.moduleData)
  );

  // Check if there is a vesting schedule
  useEffect(() => {
    setHasVesting(Boolean(formData.moduleData));
  }, [formData.moduleData]);

  // Setup polling when txId is set
  useEffect(() => {
    if (!writeContractsHash) return;

    const interval = setInterval(() => {
      refetch();
    }, 1000);

    // Clear interval when component unmounts or writeContractsHash changes
    return () => {
      clearInterval(interval);
    };
  }, [writeContractsHash, refetch]);

  // Check if the tx is confirmed
  useEffect(() => {
    if (result && result?.status === 'CONFIRMED') {
      (async () => {
        try {
          const filteredLogs = result.receipts?.[0]?.logs.filter(
            (l: any) =>
              l.address ===
              contracts[chainId!].TREXFactory.address.toLowerCase()
          );
          const mappedLogs = filteredLogs!.map((l: any) =>
            decodeEventLog({
              abi: contracts[chainId!].TREXFactory.abi as Abi,
              data: l.data,
              topics: l.topics,
            })
          );

          const event: any = mappedLogs?.filter(
            (l: any) => l.eventName === 'TREXSuiteDeployed'
          )[0];

          if (!event) {
            console.error('TREXSuiteDeployed event not found.');
            return;
          }

          const { _token: tokenAddress, _ir: ir } = event.args as any;

          // TODO: Save token_address and ir
          console.log('tokenAddress', tokenAddress);
          console.log('ir', ir);

          await axios({
            url: `${process.env.REACT_APP_API_URL}/v1/entities/me/safe_classes`,
            method: 'POST',
            data: {
              currency: zeroAddress,
              discount: formData.discount,
              valuation_cap: formData.valuationCap,
              uri: formData.uri,
              token_address: tokenAddress,
              ir: ir,
            },
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });

          navigate(`/equity/drafts/${draftId}/review`);
        } catch (error) {
          console.error('Error tokenizing:', error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [result]);

  // Fetch the draft
  useEffect(() => {
    const fetchDraft = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/drafts/${draftId}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const draft = response.data.draft;
        const draftFormData = draft.form_data;

        // Set draft type from the fetched draft
        setDraftType(draft.draft_type);

        setFormData({
          ...draftFormData,
          documents: null, // Reset documents to handle file input
        });
      } catch (error: any) {
        setError(error.response?.data?.message || error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchDraft();
  }, [draftId, auth?.token, setError]);

  const handleChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | { name: string; value: any }
  ) => {
    let name: string;
    let value: any;

    if ('target' in e) {
      // It's a React.ChangeEvent
      name = e.target.name;
      value = e.target.value;
    } else {
      // It's a custom change object
      name = e.name;
      value = e.value;
    }

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Prepare form data to send to the backend
      const formToSubmit = new FormData();
      if (!draftType) {
        throw new Error('Draft type is required');
      }
      formToSubmit.append('draft_type', draftType);

      // Recursive replacer to handle nested BigInt
      const replacer = (key: string, value: any) => {
        if (typeof value === 'bigint') {
          return value.toString();
        }
        return value;
      };

      formToSubmit.append('form_data', JSON.stringify(formData, replacer));

      // Append file if present
      if (formData.documents) {
        formToSubmit.append('file', formData.documents);
      }

      // Send updated formData to the backend to update the draft
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/drafts/${draftId}`,
        formToSubmit,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Create the new Safe class if necessary
      const createNewSafeClass = false;
      if (createNewSafeClass) {
        console.log('formData', formData);
        const terms = {
          currency: formData.currency || zeroAddress,
          discount: Number(formData.discount),
          valuationCap: Number(formData.valuationCap),
          uri: formData.uri || '',
        };
        console.log('terms', terms);
        console.log('tokenAddress', tokenAddress);
        const result = await createSafeClass({
          contracts: [
            {
              address: tokenAddress as `0x${string}`,
              abi: contracts[chainId!].SAFE.abi,
              functionName: 'createSafeClass',
              args: [terms],
            },
          ],
          chainId,
          account: address,
        });
        setWriteContractsHash(result as any);
      }

      navigate(`/equity/drafts/${draftId}/review`);
    } catch (error: any) {
      setError(error.response?.data?.message || error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading || !formData) {
    return (
      <Container className="my-5 text-center">
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <Container className="my-5">
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <h2>Edit Draft</h2>
          {/* Render the appropriate form based on draft type */}
          {draftType === 'Shares' ? (
            <StockForm
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          ) : draftType === 'Unit Interests' ? (
            <AllocationForm
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          ) : (
            <SafeForm
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EditDraft;
