// CreateOfferingDetails.tsx
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import { Helmet } from 'react-helmet';
import { useError } from '../../../contexts/ErrorContext';

const EXEMPTION_OPTIONS = {
  Equity: {
    'Reg CF': 'Reg CF',
    'Reg D 506(b)': 'Reg D 506(b)',
    'Reg D 506(c)': 'Reg D 506(c)',
  },
  SAFE: {
    'Reg D 506(b)': 'Reg D 506(b)',
    'Reg D 506(c)': 'Reg D 506(c)',
  },
};

const CreateOfferingDetails: React.FC = () => {
  const { auth } = useAuth();
  const { setError } = useError();
  const [formData, setFormData] = useState<any>({
    name: '',
    description: '',
    offeringType: 'Equity',
    offeringMarket: 'Primary',
    federalExemption: 'Reg CF',
    offeringPrice: 0,
    softCap: '',
    hardCap: '',
    minimumInvestment: '',
    investmentDeadline: '',
    promoImageFile: null,
    useOfFunds: '',
  });
  const navigate = useNavigate();
  const { setOffering } = useOffering();

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      // Assuming you want to store the file itself, not just the URL
      setFormData({
        ...formData,
        promoImageFile: e.target.files[0],
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    console.log('federal_exemption', formData.federalExemption);
    formDataToSend.append('offering_name', formData.name);
    formDataToSend.append('offering_description', formData.description);
    formDataToSend.append('offering_type', formData.offeringType);
    formDataToSend.append('offering_market', formData.offeringMarket);
    formDataToSend.append('federal_exemption', formData.federalExemption);
    formDataToSend.append('soft_cap', formData.softCap);
    formDataToSend.append('hard_cap', formData.hardCap);
    formDataToSend.append('file', formData.promoImageFile);
    formDataToSend.append('use_of_funds', formData.useOfFunds);
    formDataToSend.append('offering_type', formData.offeringType);
    formDataToSend.append('minimum_investment', formData.minimumInvestment);
    formDataToSend.append('investment_deadline', formData.investmentDeadline);

    if (formData.offeringType !== 'SAFE') {
      formDataToSend.append(
        'offering_price',
        formData.offeringPrice.toString()
      );
    }

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'multipart/form-data',
          'X-Account-Id': auth?.user.account_id,
        },
        data: formDataToSend,
      });
      const { offering_id } = response.data.result;
      setOffering(response.data.result);
      navigate(`/offerings/${offering_id}/documents/create`);
    } catch (error: any) {
      console.error(error);
      setError(error?.response?.data?.message);
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/offerings">Offerings</Link>
              </li>
              <li className="breadcrumb-item active">New</li>
            </ol>
          </nav>
        </div>
      </div>
      <Container className="my-5">
        <Helmet>Create Offering | Details</Helmet>
        <div className="row">
          <div className="col-4 offset-4">
            <h2>Create a New Offering</h2>
            <Form onSubmit={handleSubmit}>
              <h5>Offering Details</h5>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridOfferingType">
                  <Form.Label>Offering Type</Form.Label>
                  <Form.Select
                    name="offeringType"
                    value={formData.offeringType}
                    onChange={handleFormChange}
                  >
                    <option value="Equity">Equity</option>
                    <option value="SAFE">Post-money SAFE</option>
                    {/* Add other offering types as needed */}
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Offering Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter offering name"
                    value={formData.name}
                    onChange={handleFormChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    placeholder="Enter description"
                    value={formData.description}
                    onChange={handleFormChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFederalExemption">
                  <Form.Label>Federal Exemption</Form.Label>
                  <Form.Select
                    name="federalExemption"
                    value={formData.federalExemption}
                    onChange={handleFormChange}
                  >
                    {Object.entries(
                      EXEMPTION_OPTIONS[
                        formData.offeringType as keyof typeof EXEMPTION_OPTIONS
                      ]
                    ).map(([key, value]) => (
                      <option value={key}>{value}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>

              {formData.offeringType !== 'SAFE' && (
                <Row className="mb-3">
                  <Form.Group controlId="formGridOfferingPrice">
                    <Form.Label>Offering Price</Form.Label>
                    <InputGroup as={Col}>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="decimal"
                        min={0.000001}
                        minLength={2}
                        step="0.00001"
                        name="offeringPrice"
                        placeholder="Enter offering price"
                        onChange={handleFormChange}
                        value={formData.offeringPrice}
                        required
                      />
                      <InputGroup.Text>per share</InputGroup.Text>
                    </InputGroup>
                    <small className="text-muted">
                      The offering price per share.
                    </small>
                  </Form.Group>
                </Row>
              )}

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridSoftCap">
                  <Form.Label>Target Amount</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="softCap"
                      placeholder="Enter target amount"
                      value={formData.softCap}
                      onChange={handleFormChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <small className="text-muted">
                  This is the minimum amount to raise.
                </small>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridHardCap">
                  <Form.Label>Maximum Amount</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="hardCap"
                      placeholder="Enter maximum amount"
                      value={formData.hardCap}
                      onChange={handleFormChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <small className="text-muted">
                  Amount to raise beyond the target amount.
                </small>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridMinimumInvestment">
                  <Form.Label>Minimum Investment</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="minimumInvestment"
                      placeholder="Enter minimum investment"
                      value={formData.minimumInvestment}
                      onChange={handleFormChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <small className="text-muted">
                  Minimum investment per investor.
                </small>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridInvestmentDeadline">
                  <Form.Label>Investment Deadline</Form.Label>
                  <Form.Control
                    type="date"
                    name="investmentDeadline"
                    value={formData.investmentDeadline}
                    onChange={handleFormChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridPromoImageFile">
                  <Form.Label>Promo Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="promoImageFile"
                    onChange={handleFileChange}
                    required
                  />
                </Form.Group>
                <small className="text-muted">Should be 600x300 pixels.</small>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridUseOfFunds">
                  <Form.Label>Use of Funds</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="useOfFunds"
                    rows={3}
                    placeholder="Enter use of funds"
                    value={formData.useOfFunds}
                    onChange={handleFormChange}
                    required
                  />
                </Form.Group>
              </Row>

              <Button variant="primary" type="submit">
                Save and Continue
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default CreateOfferingDetails;
