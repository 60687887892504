import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import Footer from '../../components/Footer';
import LandingNav from '../../components/LandingNav';
import { useError } from '../../contexts/ErrorContext';

export default function Login() {
  const { auth, setAuth } = useAuth();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const { setError } = useError();
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the 'from' location state
  const from = location.state?.from?.pathname || '/portfolio';

  useEffect(() => {
    if (auth) {
      navigate(from, { replace: true });
    }
  }, [auth]);

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/v1/users/login`,
        {
          method: 'POST',
          data: {
            email,
            password,
          },
        }
      );
      if (response.status === 200) {
        setAuth(response.data);
        const token = response.data.token;
        const account_id = response.data.user.account_id;
        try {
          const response = await axios(
            `${process.env.REACT_APP_API_URL}/v1/users/me`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Account-Id': account_id,
              },
            }
          );
          const { onboarded, email_verified } = response.data.result;

          if (onboarded) {
            // Navigate back to the attempted URL
            navigate(from, { replace: true });
            // navigate('/portfolio');
          } else {
            if (!email_verified) {
              return navigate('/confirm-email');
            } else {
              navigate('/onboarding');
            }
          }
        } catch (error) {
          console.log(error);
          setError(error);
        }
      }
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401) alert(error.response.data.message);
      else alert(error.message);
    }
  };

  return (
    <>
      <LandingNav />
      <div className="min-vh-100 bg-primary">
        <div className="h-100 d-flex flex-column align-items-center justify-content-center mx-2">
          <div className="col-lg-4 col-12">
            <div className="d-flex justify-content-center align-items-center">
              <Card className="w-100 mt-4">
                <Card.Body>
                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      {/* <img
                      src={logo}
                      alt="CapSign"
                      className="img-fluid"
                    /> */}
                      <h1 className="my-4 text-primary">Login</h1>
                    </div>
                  </div>
                  <form onSubmit={login}>
                    <div className="row">
                      <div className="offset-2 col-8">
                        <hr />
                      </div>
                    </div>
                    <div className="row mt-5 mb-3">
                      <div className="col-lg-6 offset-lg-3">
                        <div className="mb-3">
                          <label className="form-label">Email address</label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                        </div>
                        <div className="d-grid gap-2 mb-2">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row mb-3">
                    <div className="col-12 text-center">
                      {/* <div>
                        <small>
                          Don't have an account?{' '}
                          <Link
                            to="/register"
                            state={{ from: location.state?.from }}
                          >
                            Register
                          </Link>
                        </small>
                      </div> */}
                      <div>
                        <small>
                          Forgot password?{' '}
                          <Link to="/reset-password">Reset</Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
