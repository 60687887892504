import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';

interface InterestFormProps {
  formData: InterestFormData;
  handleChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleSubmit: (e: React.FormEvent) => void;
  loading: boolean;
}

interface InterestFormData {
  // Contributor Information
  name: string;
  email: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  accountAddress: string;

  // Interest Details
  interestClassId: string;
  quantity: number;
  allocationDate: string;
  ownershipPercentage: number;
  allocationType: 'Cash' | 'Property' | 'Services';
  nonCashDescription: string;
  paymentMethod: string;
  paymentSchedule: string;

  // Interest Agreement
  effectiveDate: string;
  interestClass: string;

  // Acknowledgment
  confirmInformation: boolean;
  signature: string;

  // Supporting Documents
  file: File | null;

  // New fields from Types.sol
  committedCapital: number;
  paidInCapital: number;
  unfundedCommitment: number;
  lockedUntil: string;
  redeemableAmount: number;
  expirationDate: string;
  currentCapitalCallAmount: number;
  capitalCallActive: boolean;
  accepted: boolean;
}

interface InterestClass {
  class_id: string;
  class_name: string;
}

const InterestForm: React.FC<InterestFormProps> = ({
  formData,
  handleChange,
  handleSubmit,
  loading,
}) => {
  // Add state to hold interest classes
  const [interestClasses, setInterestClasses] = useState<InterestClass[]>([]);
  const { auth } = useAuth();

  useEffect(() => {
    // Fetch interest classes from the API when the component mounts
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/entities/me/interest_classes`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
      })
      .then((response) => {
        setInterestClasses(response.data.result);
      })
      .catch((error) => {
        console.error('Error fetching interest classes:', error);
      });
  }, []);

  // Calculate Unfunded Commitment
  const committedCapital = Number(formData.committedCapital) || 0;
  const paidInCapital = Number(formData.paidInCapital) || 0;
  const unfundedCommitment = committedCapital - paidInCapital;

  return (
    <Form onSubmit={handleSubmit}>
      {/* Recipient Information */}
      <h4>Recipient Information</h4>

      {/* Full Name */}
      <Form.Group controlId="name" className="mb-3">
        <Form.Label>
          Full Name<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Email */}
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>
          Email Address<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Account Address */}
      <Form.Group controlId="accountAddress" className="mb-3">
        <Form.Label>
          Account Address<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="accountAddress"
          value={formData.accountAddress}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Phone Number */}
      <Form.Group controlId="phoneNumber" className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Mailing Address */}
      <Form.Group controlId="addressLine1" className="mb-3">
        <Form.Label>Address Line 1</Form.Label>
        <Form.Control
          type="text"
          name="addressLine1"
          value={formData.addressLine1}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="addressLine2" className="mb-3">
        <Form.Label>Address Line 2</Form.Label>
        <Form.Control
          type="text"
          name="addressLine2"
          value={formData.addressLine2}
          onChange={handleChange}
        />
      </Form.Group>

      <Row>
        <Col md={6}>
          <Form.Group controlId="city" className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="state" className="mb-3">
            <Form.Label>State/Province</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="zipCode" className="mb-3">
            <Form.Label>Zip/Postal Code</Form.Label>
            <Form.Control
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="country" className="mb-3">
        <Form.Label>Country</Form.Label>
        <Form.Control
          type="text"
          name="country"
          value={formData.country}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Interest Details */}
      <h4 className="mt-4">Interest Details</h4>

      {/* Interest Class */}
      <h4 className="mt-4">Interest Class</h4>
      <Form.Group controlId="interestClassId" className="mb-3">
        <Form.Label>
          Select Interest Class<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="interestClassId"
          value={formData.interestClassId}
          onChange={handleChange}
          required
        >
          <option value="">Select Interest Class</option>
          {interestClasses.map((interestClass) => (
            <option key={interestClass.class_id} value={interestClass.class_id}>
              {interestClass.class_name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Interest Amount */}
      <Form.Group controlId="quantity" className="mb-3">
        <Form.Label>
          Quantity (Units)<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="quantity"
          value={formData.quantity}
          onChange={handleChange}
          required
          min={0}
          step={0.01}
        />
      </Form.Group>

      {/* Interest Date */}
      <Form.Group controlId="allocationDate" className="mb-3">
        <Form.Label>
          Interest Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="allocationDate"
          value={formData.allocationDate}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Committed Capital */}
      <Form.Group controlId="committedCapital" className="mb-3">
        <Form.Label>Committed Capital</Form.Label>
        <Form.Control
          type="number"
          name="committedCapital"
          value={formData.committedCapital}
          onChange={handleChange}
          min={0}
          step={0.01}
        />
      </Form.Group>

      {/* Paid In Capital */}
      <Form.Group controlId="paidInCapital" className="mb-3">
        <Form.Label>Paid In Capital</Form.Label>
        <Form.Control
          type="number"
          name="paidInCapital"
          value={formData.paidInCapital}
          onChange={handleChange}
          min={0}
          step={0.01}
        />
      </Form.Group>

      {/* Unfunded Commitment */}
      <Form.Group controlId="unfundedCommitment" className="mb-3">
        <Form.Label>Unfunded Commitment</Form.Label>
        <Form.Control
          type="number"
          name="unfundedCommitment"
          value={unfundedCommitment}
          readOnly
          disabled
        />
      </Form.Group>

      {/* Allocation Date */}
      <Form.Group controlId="allocationDate" className="mb-3">
        <Form.Label>Allocation Date</Form.Label>
        <Form.Control
          type="date"
          name="allocationDate"
          value={formData.allocationDate}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Locked Until */}
      <Form.Group controlId="lockedUntil" className="mb-3">
        <Form.Label>Locked Until</Form.Label>
        <Form.Control
          type="date"
          name="lockedUntil"
          value={formData.lockedUntil}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Redeemable Amount */}
      <Form.Group controlId="redeemableAmount" className="mb-3">
        <Form.Label>Redeemable Amount</Form.Label>
        <Form.Control
          type="number"
          name="redeemableAmount"
          value={formData.redeemableAmount}
          onChange={handleChange}
          min={0}
          step={0.01}
        />
      </Form.Group>

      {/* Expiration Date */}
      <Form.Group controlId="expirationDate" className="mb-3">
        <Form.Label>Expiration Date</Form.Label>
        <Form.Control
          type="date"
          name="expirationDate"
          value={formData.expirationDate}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Capital Call Active */}
      <Form.Group controlId="capitalCallActive" className="mb-3">
        <Form.Check
          type="checkbox"
          name="capitalCallActive"
          label="Capital Call Active"
          checked={formData.capitalCallActive}
          onChange={handleChange}
        />
      </Form.Group>

      {/* Current Capital Call Amount */}
      <Form.Group controlId="currentCapitalCallAmount" className="mb-3">
        <Form.Label>Current Capital Call Amount</Form.Label>
        <Form.Control
          type="number"
          name="currentCapitalCallAmount"
          value={formData.currentCapitalCallAmount}
          onChange={handleChange}
          min={0}
          step={0.01}
        />
      </Form.Group>

      {/* Ownership Percentage */}
      <Form.Group controlId="ownershipPercentage" className="mb-3">
        <Form.Label>
          Ownership Percentage (%)<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          name="ownershipPercentage"
          value={formData.ownershipPercentage}
          onChange={handleChange}
          required
          min={0}
          max={100}
          step={0.01}
        />
      </Form.Group>

      {/* Interest Type */}
      <Form.Group controlId="allocationType" className="mb-3">
        <Form.Label>
          Type of Interest<span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          name="allocationType"
          value={formData.allocationType}
          onChange={handleChange}
          required
        >
          <option value="">Select Interest Type</option>
          <option value="Cash">Cash</option>
          <option value="Property">Property</option>
          <option value="Services">Services</option>
        </Form.Select>
      </Form.Group>

      {/* Description of Non-Cash Contributions */}
      {formData.allocationType !== 'Cash' && (
        <Form.Group controlId="nonCashDescription" className="mb-3">
          <Form.Label>
            Description of Non-Cash Interest
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="nonCashDescription"
            value={formData.nonCashDescription}
            onChange={handleChange}
            required
            rows={3}
          />
        </Form.Group>
      )}

      {/* Payment Method */}
      <Form.Group controlId="paymentMethod" className="mb-3">
        <Form.Label>
          Payment Method<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="paymentMethod"
          value={formData.paymentMethod}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Payment Schedule */}
      <Form.Group controlId="paymentSchedule" className="mb-3">
        <Form.Label>Payment Schedule (if not lump sum)</Form.Label>
        <Form.Control
          as="textarea"
          name="paymentSchedule"
          value={formData.paymentSchedule}
          onChange={handleChange}
          rows={2}
        />
      </Form.Group>

      {/* Subscription Agreement */}
      <h4 className="mt-4">Subscription Agreement</h4>

      {/* Effective Date */}
      <Form.Group controlId="effectiveDate" className="mb-3">
        <Form.Label>
          Effective Date<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          name="effectiveDate"
          value={formData.effectiveDate}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Supporting Documents */}
      <Form.Group controlId="file" className="mb-4">
        <Form.Label>Subscription Agreement</Form.Label>
        <Form.Control
          type="file"
          name="file"
          onChange={handleChange}
          accept=".pdf"
        />
        <Form.Text className="text-muted">
          Accepted formats: PDF, DOC, DOCX, TXT, JPG, PNG
        </Form.Text>
      </Form.Group>

      {/* Acknowledgment */}
      <h4 className="mt-4">Acknowledgment</h4>

      {/* Confirm Information */}
      <Form.Group controlId="confirmInformation" className="mb-3">
        <Form.Check
          type="checkbox"
          name="confirmInformation"
          label="I confirm that all information provided is accurate and complete."
          checked={formData.confirmInformation}
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* Signature */}
      <Form.Group controlId="signature" className="mb-3">
        <Form.Label>
          Signature<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="signature"
          value={formData.signature}
          onChange={handleChange}
          required
        />
        <Form.Text className="text-muted">
          Please type your full name as a signature.
        </Form.Text>
      </Form.Group>

      {/* Submit Button */}
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? <Spinner size="sm" /> : 'Submit'}
      </Button>
    </Form>
  );
};

export default InterestForm;
