import { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Download from '../../pages/Download';
import Home from '../../pages/Home';
import Blog from '../../pages/Blog';
import Login from '../../pages/Login';
import Register from '../../pages/Register';
import ResetPassword from '../../pages/ResetPassword';
import VerifyEmail from '../../pages/VerifyEmail';
import AuthenticatedAppContent from '../AuthenticatedAppContent';
import axios from 'axios';
import './index.css';
import { useAuth } from '../../contexts/AuthContext';
import AccountType from '../../pages/Onboarding/AccountType';
import IndividualName from '../../pages/Onboarding/Individual/Name';
import IndividualAddress from '../../pages/Onboarding/Individual/Address';
import IdentityDocument from '../../pages/Onboarding/Individual/IdentityDocument';
import EntityDetails from '../../pages/Onboarding/Entity/Details';
import EntityRegisteredAddress from '../../pages/Onboarding/Entity/RegisteredAddress';
import EntityPrincipalAddress from '../../pages/Onboarding/Entity/PrincipalAddress';
import EntityMailingAddress from '../../pages/Onboarding/Entity/MailingAddress';
import EntityRegistrationNumber from '../../pages/Onboarding/Entity/RegistrationNumber';
import EntityCertificate from '../../pages/Onboarding/Entity/FormationCertificate';
import EntityAuthorization from '../../pages/Onboarding/Entity/CorporateAuthorization';
import EntityAccreditation from '../../pages/Onboarding/Entity/Accreditation';
import CreateIdentity from '../../pages/Onboarding/Wallet/CreateIdentity';
import VerifyIdentity from '../../pages/Onboarding/VerifyIdentity';
import Connect from '../../pages/Onboarding/Connect';
import ConfirmEmail from '../../pages/ConfirmEmail';
import Onboarding from '../../pages/Onboarding';
import AcceptInvite from '../../pages/AcceptInvite';
import Company from '../../pages/Company';
import Platform from '../../pages/Platform';
import Solutions from '../../pages/Solutions';
import CustodialWallet from '../../pages/Onboarding/Wallet/CustodialWallet';
import SelfCustodyWallet from '../../pages/Onboarding/Wallet/SelfCustodyWallet';
import MessageToast from '../../components/MessageToast';
import ErrorToast from '../../components/ErrorToast';
import Fundraising from '../../pages/Home/Fundraising';

function App() {
  const { auth } = useAuth();
  const location = useLocation();

  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>CapSign</title>
        </Helmet>
        {(process.env.REACT_APP_REQUIRE_AUTH === 'true' ? auth : true) ? (
          // Authenticated routes
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/platform" element={<Platform />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/company" element={<Company />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/register" element={<Register />} />
            <Route path="/onboarding" element={<Onboarding />}>
              {/* <Route index element={<Navigate replace to="/onboarding/account" />} /> */}
              <Route path="account">
                <Route index element={<AccountType />} />
              </Route>
              <Route path="individual">
                <Route path="name" element={<IndividualName />} />
                <Route path="address" element={<IndividualAddress />} />
                <Route path="identity" element={<IdentityDocument />} />
              </Route>
              <Route path="entity">
                <Route path="details" element={<EntityDetails />} />
                <Route
                  path="registered-address"
                  element={<EntityRegisteredAddress />}
                />
                <Route
                  path="principal-address"
                  element={<EntityPrincipalAddress />}
                />
                <Route
                  path="mailing-address"
                  element={<EntityMailingAddress />}
                />
                <Route
                  path="registration-number"
                  element={<EntityRegistrationNumber />}
                />
                <Route
                  path="formation-certificate"
                  element={<EntityCertificate />}
                />
                <Route
                  path="corporate-authorization"
                  element={<EntityAuthorization />}
                />
                <Route path="accreditation" element={<EntityAccreditation />} />
              </Route>
              <Route path="create-id" element={<CreateIdentity />} />
              <Route path="verify-id" element={<VerifyIdentity />} />
            </Route>
            <Route path="/blog" element={<Blog />} />
            <Route path="/download-app" element={<Download />} />
            <Route path="*" element={<AuthenticatedAppContent />} />
          </Routes>
        ) : (
          // Unauthenticated routes
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/platform" element={<Platform />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/company" element={<Company />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/accept-invite" element={<AcceptInvite />} />
            <Route
              path="*"
              element={
                <Navigate to="/login" state={{ from: location }} replace />
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        )}
      </div>
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        {<ErrorToast />}
        {<MessageToast />}
      </div>
    </HelmetProvider>
  );
}

const LoginCallback = ({ setAuth }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Detect auth code for login
   */
  useEffect(() => {
    (async () => {
      try {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        // Get auth token
        let url;
        if (process.env.NODE_ENV === 'production')
          url = `https://api.capsign.com/v1/auth/token`;
        else url = `http://localhost:8080/v1/auth/token`;
        let response = await axios({
          method: 'GET',
          url,
          headers: {
            'Content-AccountType': 'application/x-www-form-urlencoded',
          },
          params: { code },
        });

        const {
          access_token: accessToken,
          id_token: idToken,
          expires_in: expiresIn,
        } = response.data;
        const expiresAt = new Date();
        expiresAt.setSeconds(expiresAt.getSeconds() + Number(expiresIn));

        if (accessToken) {
          response = await axios({
            method: 'GET',
            url: 'https://api.global.id/v1/identities/me',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (response.status === 200) {
            setAuth({ accessToken, idToken, expiresAt });
            navigate('/profile');
          } else {
            console.error(response);
          }
        } else {
          navigate('/login');
        }
      } catch (error: any) {
        console.log('Failed to fetch identity.');
        console.error(error);

        if (error?.response?.status === 401) {
          navigate('/login');
        } else {
          navigate('/login');
        }
      }
    })();
  }, [location, navigate, setAuth]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <span className="fa fa-spin fa-spinner fa-3x" />
    </div>
  );
};

export default App;
