import React, { useEffect, useMemo } from 'react';
import { Card, ProgressBar, Button, Spinner, Badge } from 'react-bootstrap';
import { contracts } from '../../../constants';
import moment from 'moment';
import {
  useReadContract,
  useWriteContract,
  useChainId,
  useWalletClient,
  useWaitForTransactionReceipt,
  useAccount,
  usePublicClient,
} from 'wagmi';
import {
  decimals,
  subscriptionToken,
  subscriptionTokenAddress,
} from '../../../utils';
import { useError } from '../../../contexts/ErrorContext';
import { Abi, decodeEventLog, formatUnits, zeroAddress } from 'viem';
import {
  useCallsStatus,
  useCapabilities,
  useWriteContracts,
} from 'wagmi/experimental';
import axios from 'axios';

const CardPlanDetails = () => {
  const { address: account } = useAccount();
  const [loading, setLoading] = React.useState(false);
  const chainId = useChainId();
  const { setError } = useError();
  const publicClient = usePublicClient();
  const { writeContractAsync: approve } = useWriteContract();
  const { data: walletClient } = useWalletClient();
  const [writeContractsHash, setWriteContractsHash] = React.useState<
    string | null
  >(null);
  const { data: result, refetch } = useCallsStatus({
    id: writeContractsHash || '',
  });

  const contractAddress = contracts[chainId!]?.SubscriptionManager?.address;
  const usdcAbi = [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'allowance', type: 'uint256' },
        { internalType: 'uint256', name: 'needed', type: 'uint256' },
      ],
      name: 'ERC20InsufficientAllowance',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'uint256', name: 'balance', type: 'uint256' },
        { internalType: 'uint256', name: 'needed', type: 'uint256' },
      ],
      name: 'ERC20InsufficientBalance',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'approver', type: 'address' }],
      name: 'ERC20InvalidApprover',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'receiver', type: 'address' }],
      name: 'ERC20InvalidReceiver',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
      name: 'ERC20InvalidSender',
      type: 'error',
    },
    {
      inputs: [{ internalType: 'address', name: 'spender', type: 'address' }],
      name: 'ERC20InvalidSpender',
      type: 'error',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ];
  const contractABI = contracts[chainId!]?.SubscriptionManager?.abi;

  const { data: subscriptionFee } = useReadContract({
    address: contractAddress as `0x${string}`,
    abi: contractABI,
    functionName: 'subscriptionFee',
  });

  const { data: isSubscribed, refetch: refetchIsSubscribed } = useReadContract({
    address: contractAddress as `0x${string}`,
    abi: contractABI,
    functionName: 'isSubscribed',
    args: [account],
  });

  const { data: subscriptionDetails, refetch: refetchSubscriptionDetails } =
    useReadContract({
      address: contractAddress as `0x${string}`,
      abi: contractABI,
      functionName: 'getSubscriptionDetails',
      args: [account],
    });

  const startTime = subscriptionDetails ? Number(subscriptionDetails[1]) : null;
  const endTime = subscriptionDetails ? Number(subscriptionDetails[2]) : null;

  const { writeContractsAsync: subscribe } = useWriteContracts();
  const { writeContractAsync: cancelSubscription } = useWriteContract();

  const { data: availableCapabilities } = useCapabilities({
    account: account,
  });

  // const capabilities = useMemo(() => {
  //   if (!availableCapabilities || !chainId) return {};
  //   const capabilitiesForChain = availableCapabilities[chainId];
  //   if (
  //     capabilitiesForChain?.paymasterService &&
  //     capabilitiesForChain.paymasterService.supported
  //   ) {
  //     return {
  //       paymasterService: {
  //         url: process.env.REACT_APP_PAYMASTER_URL,
  //       },
  //     };
  //   }
  //   return {};
  // }, [availableCapabilities, chainId]);

  const capabilities = useMemo(
    () => ({
      paymasterService: {
        url: process.env.REACT_APP_PAYMASTER_URL,
      },
    }),
    [process.env.REACT_APP_PAYMASTER_URL]
  );

  const checkAndApproveToken = async (
    amount: bigint,
    tokenAddress: string,
    spenderAddress: string
  ) => {
    if (tokenAddress === zeroAddress) return true; // Skip if native ETH

    const currentAllowance: any = await publicClient?.readContract({
      address: subscriptionTokenAddress[chainId!] as `0x${string}`,
      abi: usdcAbi,
      functionName: 'allowance',
      args: [account, spenderAddress],
    });

    if (BigInt(currentAllowance) < amount) {
      const params = {
        account,
        address: subscriptionTokenAddress[chainId!] as `0x${string}`,
        abi: usdcAbi,
        functionName: 'approve',
        args: [spenderAddress, amount],
      };
      const gas = await publicClient?.estimateContractGas(params);
      const approveTx = await approve({ ...params, gas });
      await publicClient?.waitForTransactionReceipt({ hash: approveTx });
    }
    return true;
  };

  // Setup polling when txId is set
  useEffect(() => {
    if (!writeContractsHash) return;

    const interval = setInterval(() => {
      refetch();
    }, 1000);

    // Clear interval when component unmounts or writeContractsHash changes
    return () => {
      clearInterval(interval);
    };
  }, [writeContractsHash, refetch]);

  useEffect(() => {
    if (result && result?.status === 'CONFIRMED') {
      (async () => {
        try {
          const filteredLogs = result.receipts?.[0]?.logs.filter(
            (l: any) =>
              l.address ===
              contracts[chainId!].SubscriptionManager.address.toLowerCase()
          );
          const mappedLogs = filteredLogs!.map((l: any) =>
            decodeEventLog({
              abi: contracts[chainId!].SubscriptionManager.abi as Abi,
              data: l.data,
              topics: l.topics,
            })
          );

          const event: any = mappedLogs?.filter(
            (l: any) => l.eventName === 'Subscribed'
          )[0];

          if (!event) {
            console.error('Subscribed event not found.');
            return;
          }

          await refetchIsSubscribed();
          await refetchSubscriptionDetails();
          setWriteContractsHash(null);
        } catch (error) {
          console.error('Error with subscription status:', error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [result]);

  /**
   * @function handleSubscribe
   */
  const handleSubscribe = async () => {
    setLoading(true);
    try {
      const token = subscriptionTokenAddress[chainId!];
      const spender = contracts[chainId!]?.SubscriptionManager.address;
      const amount = subscriptionFee!;

      // Check balance
      const balance = (await publicClient?.readContract({
        address: subscriptionTokenAddress[chainId!] as `0x${string}`,
        abi: usdcAbi,
        functionName: 'balanceOf',
        args: [account],
      })) as bigint;

      if (BigInt(balance) < amount) {
        alert('Insufficient balance to subscribe.');
        return;
      }

      // Batch approve and subscribe transactions
      const paramsApprove: any = {
        abi: usdcAbi,
        functionName: 'approve',
        args: [spender, amount],
        address: subscriptionTokenAddress[chainId!] as `0x${string}`,
      };

      const paramsSubscribe: any = {
        abi: contractABI,
        functionName: 'subscribe',
        address: contractAddress as `0x${string}`,
      };

      const hash = await subscribe({
        contracts: [paramsApprove, paramsSubscribe],
        capabilities,
        account: walletClient?.account,
        chainId,
      });

      setWriteContractsHash(hash);
    } catch (error) {
      console.error('Subscription failed', error);
      setError(error);
    }
  };

  /**
   * @function handleCancelSubscription
   */
  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const hash = await cancelSubscription({
        address: contractAddress as `0x${string}`,
        abi: contractABI,
        functionName: 'cancelSubscription',
      });
      await publicClient?.waitForTransactionReceipt({ hash });
      refetchSubscriptionDetails(); // Refresh subscription details after cancelling
    } catch (error) {
      console.error('Cancel subscription failed', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @function calculateTimeLeft
   * @returns
   */
  const calculateTimeLeft = () => {
    const _endTime = moment.unix(endTime!);
    const now = moment();
    const duration = moment.duration(_endTime.diff(now));
    return `${duration.days()} days ${duration.hours()} hours left`;
  };

  const totalDuration = 30; // 30 days

  // isSubscribed = true and timeLeft = 0 means a waiver is active
  const timeLeft = isSubscribed ? endTime ? calculateTimeLeft() : <></> : '';
  const progress =
    startTime && endTime
      ? ((moment().unix() - startTime) / (endTime - startTime)) * 100
      : 0;
  const hasWaiver = endTime === 0;

  return (
    <Card className="shadow-sm">
      <Card.Body className="py-4">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="h5 fw-semibold mb-1">Standard Plan</h6>
          <span
            className={`badge ${isSubscribed ? 'text-bg-primary' : 'text-bg-secondary'}`}
          >
            {isSubscribed && !hasWaiver
              ? 'Active'
              : isSubscribed
                ? 'Waiver Active'
                : 'Inactive'}
          </span>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-5">
          <div>
            {/* <p className="text-sm text-muted">
              Create and manage on-chain equity and Regulation CF offerings.
            </p> */}
            <p className="text-sm text-muted">
              Create and manage on-chain equity issuances with premium support.
              Secondary transaction functionality coming soon!
            </p>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <span className="fw-semibold text-2xl mt-1 me-1"></span>
              <span>
                {subscriptionFee &&
                  formatUnits(subscriptionFee, decimals[chainId!])?.toString()}
              </span>
              <span className="text-muted text-lg ms-1">
                {subscriptionToken[chainId!]} / month
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <span className="text-sm fw-semibold">
            Billing cycle: {totalDuration} days
          </span>
          <span className="text-sm fw-semibold">{timeLeft}</span>
        </div>
        <ProgressBar
          now={progress}
          className="progress-sm mb-6"
          animated={progress < 100}
        />
        <hr />
        <div className="d-flex justify-content-end">
          {isSubscribed && Number(timeLeft) > 0 ? (
            <Button
              variant="none"
              className="text-danger"
              onClick={handleCancelSubscription}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                'Cancel plan'
              )}
            </Button>
          ) : isSubscribed ? (
            <></>
          ) : (
            <Button onClick={handleSubscribe} disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                'Subscribe'
              )}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardPlanDetails;
