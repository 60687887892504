import { Helmet } from 'react-helmet-async';
import CertificateList from './CertificateList';
import SafeList from './SafeList';
import Listings from './Listings';
import Bids from './Bids';

export default function Portfolio() {
  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <li className="breadcrumb-item active">Portfolio</li>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row px-5 py-4">
          <div className="col-12">
            <Helmet>
              <title>CapSign | Portfolio</title>
            </Helmet>
            <CertificateList />
            <SafeList />
            <div className="pb-5">
              <h2>Activity</h2>
              <div className="row">
                <div className="col-12">
                  <Listings />
                </div>
                <div className="mt-4">
                  <div className="col-12">
                    <Bids />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
