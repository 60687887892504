// src/config/accountTypes.ts

export type AccountType = {
  label: string;
  value: string;
  ownerType: string;
  description: string;
};

export type AccountSubtype = {
  parentType: string;
  label: string;
  value: string;
  description: string;
};

export const ACCOUNT_TYPES: AccountType[] = [
  {
    label: 'Individual',
    value: 'individual',
    ownerType: 'individual',
    description:
      'This account type is for personal investing or selling shares from your personal holdings.',
  },
  {
    label: 'Business Entity',
    value: 'business_entity',
    ownerType: 'legal_entity',
    description:
      'This account type is for users acting on behalf of a business entity.',
  },
  {
    label: 'Fiduciary',
    value: 'fiduciary',
    ownerType: 'Fiduciary',
    description:
      'An account managed by a fiduciary for a beneficiary, such as a trust or guardianship.',
  },
  {
    label: 'Joint Account',
    value: 'joint',
    ownerType: 'Joint',
    description:
      'This account type is for shared accounts between multiple users.',
  },
  {
    label: 'Non-Profit Organization',
    value: 'non_profit',
    ownerType: 'legal_entity',
    description:
      'This account type is for registered non-profit organizations.',
  },
  {
    label: 'Government Agency',
    value: 'government_agency',
    ownerType: 'legal_entity',
    description: 'This account type is for official government agencies.',
  },
];

export const ACCOUNT_SUBTYPES: AccountSubtype[] = [
  // ---- INDIVIDUAL SUBTYPES ----
  {
    parentType: 'individual',
    label: 'Individual',
    value: 'individual',
    description:
      'This account type is for personal investing or selling shares from your personal holdings.',
  },
  {
    parentType: 'individual',
    label: 'Traditional IRA',
    value: 'traditional_ira',
    description:
      'A tax-advantaged retirement account with pre-tax contributions.',
  },
  {
    parentType: 'individual',
    label: 'Roth IRA',
    value: 'roth_ira',
    description:
      'A tax-advantaged retirement account with post-tax contributions.',
  },
  {
    parentType: 'individual',
    label: 'SEP IRA',
    value: 'sep_ira',
    description:
      'A Simplified Employee Pension account for self-employed individuals and small business owners.',
  },

  // ---- BUSINESS ENTITY SUBTYPES ----
  {
    parentType: 'business_entity',
    label: 'C Corporation',
    value: 'c_corporation',
    description: 'A business structured as a C Corporation.',
  },
  {
    parentType: 'business_entity',
    label: 'S Corporation',
    value: 's_corporation',
    description: 'A business structured as an S Corporation.',
  },
  {
    parentType: 'business_entity',
    label: 'LLC',
    value: 'llc',
    description: 'A business structured as a Limited Liability Company.',
  },
  {
    parentType: 'business_entity',
    label: 'Partnership',
    value: 'partnership',
    description: 'A business structured as a Partnership.',
  },

  // ---- FIDUCIARY SUBTYPES ----
  {
    parentType: 'fiduciary',
    label: 'Trust',
    value: 'trust',
    description:
      'A trust account managed by a trustee (e.g., revocable, irrevocable, living, etc.).',
  },
  {
    parentType: 'fiduciary',
    label: '401(k)',
    value: '401k',
    description:
      'An employer-sponsored retirement account managed by a fiduciary for employees.',
  },
  {
    parentType: 'fiduciary',
    label: 'ESOP',
    value: 'esop', // was "esop_trust", but renamed for brevity
    description:
      'An Employee Stock Ownership Plan (often structured as a trust).',
  },
  {
    parentType: 'fiduciary',
    label: 'Guardianship',
    value: 'guardianship',
    description:
      'Account managed by a guardian for a minor or incapacitated individual.',
  },
  {
    parentType: 'fiduciary',
    label: 'Conservatorship',
    value: 'conservatorship',
    description:
      'Account managed by a conservator for an adult who cannot manage their own affairs.',
  },
  {
    parentType: 'fiduciary',
    label: 'Estate',
    value: 'estate',
    description:
      'Account managed by an executor or administrator of an estate.',
  },
  {
    parentType: 'fiduciary',
    label: 'Custodial',
    value: 'custodial',
    description:
      'Account managed by a custodian for the benefit of a minor under UTMA/UGMA.',
  },
  {
    parentType: 'fiduciary',
    label: 'Other',
    value: 'other_fiduciary',
    description: 'Other types of fiduciary accounts.',
  },

  // ---- GOVERNMENT AGENCY SUBTYPES ----
  {
    parentType: 'government_agency',
    label: 'Federal Agency',
    value: 'federal_agency',
    description: 'A government agency operating at the federal level.',
  },
  {
    parentType: 'government_agency',
    label: 'State Agency',
    value: 'state_agency',
    description: 'A government agency operating at the state level.',
  },
  {
    parentType: 'government_agency',
    label: 'Local Agency',
    value: 'local_agency',
    description: 'A government agency operating at the local level.',
  },

  // ---- NON-PROFIT ORGANIZATION SUBTYPES ----
  {
    parentType: 'non_profit',
    label: 'Public Charity',
    value: 'public_charity',
    description:
      'A non-profit organization that receives funding from the general public.',
  },
  {
    parentType: 'non_profit',
    label: 'Private Foundation',
    value: 'private_foundation',
    description:
      'A non-profit organization funded by an individual, family, or corporation.',
  },
  {
    parentType: 'non_profit',
    label: 'Religious Organization',
    value: 'religious_organization',
    description:
      'An organization dedicated to religious worship and activities.',
  },
  {
    parentType: 'non_profit',
    label: 'Educational Institution',
    value: 'educational_institution',
    description:
      'A non-profit focusing on educational activities and programs.',
  },
  {
    parentType: 'non_profit',
    label: 'Social Advocacy Organization',
    value: 'social_advocacy',
    description: 'An organization promoting social causes and advocacy.',
  },
  {
    parentType: 'non_profit',
    label: 'Trade Association',
    value: 'trade_association',
    description:
      'An organization founded and funded by businesses in a specific industry.',
  },
  {
    parentType: 'non_profit',
    label: 'Other Non-Profit',
    value: 'other_non_profit',
    description: 'Other types of non-profit organizations.',
  },
];
