import React, { useState, useEffect, useMemo } from 'react';
import { useChainId, useAccount, usePublicClient } from 'wagmi';
import { useWriteContracts } from 'wagmi/experimental';
import Container from 'react-bootstrap/Container';
import { contracts } from '../../../constants';
import Form from 'react-bootstrap/Form';
import { Button, Card, InputGroup } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';
import { useNavigate, useParams } from 'react-router-dom';

function RegisterStakeholder() {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const [email, setEmail] = useState('');
  const [identityAddress, setIdentityAddress] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const { auth } = useAuth();
  const [identityRegistry, setIdentityRegistry] = useState<
    string | undefined
  >();
  const { setError } = useError();
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const [loading, setLoading] = useState(false);
  const publicClient = usePublicClient();
  const location = useLocation();

  const capabilities = useMemo(
    () => ({
      paymasterService: {
        url: process.env.REACT_APP_PAYMASTER_URL,
      },
    }),
    [process.env.REACT_APP_PAYMASTER_URL]
  );

  /**
   * @function fetchUserDetails
   * @description Fetches identity and wallet address based on email
   */
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/identities/search?email=${encodeURIComponent(email)}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      const { identity_address, wallet_address } = response.data.result;
      setIdentityAddress(identity_address);
      setWalletAddress(wallet_address);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
      setError(error);
    }
  };

  /**
   * Set token registry address
   */
  useEffect(() => {
    (async () => {
      try {
        const identityRegistry = await publicClient?.readContract({
          abi: contracts[chainId!]?.ERC7752.abi,
          functionName: 'identityRegistry',
          address: location.state.token_address,
        });
        setIdentityRegistry(identityRegistry);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, chainId]);

  const { writeContractsAsync: registerIdentity } = useWriteContracts();

  /**
   * @function handleRegister
   * @param event
   * @returns
   */
  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!account || !chainId || !walletAddress || !identityAddress) {
      alert('Please check your wallet connection and input.');
      setLoading(false);
      return;
    }

    try {
      const args = [
        walletAddress as `0x${string}`,
        identityAddress as `0x${string}`,
        42,
      ];

      // Prepare the contract call parameters
      const params = {
        abi: contracts[chainId!]?.IdentityRegistry.abi,
        functionName: 'registerIdentity',
        address: identityRegistry as `0x${string}`,
        args,
        account,
      };

      await publicClient?.estimateContractGas(params);

      // Execute the contract call
      const statusCallId = await registerIdentity({
        contracts: [params],
        account,
        chainId,
        capabilities,
      });

      // Add account to token identity registry in database
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/entities/me/tokens/${location.state.token_id}/identities`,
        {
          wallet_address: walletAddress,
          identity_address: identityAddress,
          chain_id: chainId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      alert('Identity registered successfully.');
      setIdentityAddress('');
      setWalletAddress('');
      navigate(-1);
    } catch (error) {
      console.error('Registration failed:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/safes">Safes</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/safes/identities`} state={location.state}>
                  Permissions
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Register User
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Card className="col-4 offset-4 mt-5">
        <Card.Header>
          <h4 className="mb-0">Register User</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleRegister}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email Address</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant="outline-secondary" onClick={fetchUserDetails}>
                  Search
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="identityAddress">
              <Form.Label>User's Identity Contract</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={identityAddress}
                onChange={(e) => setIdentityAddress(e.target.value)}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="walletAddress">
              <Form.Label>User's Wallet Address</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="countryCode">
              <Form.Label>Country</Form.Label>
              <Form.Select aria-label="Country select" disabled value="42">
                <option value="42">United States</option>
                {/* Even though the select is disabled and has only one option, 
                    it's important to include the value attribute matching the option's value. */}
              </Form.Select>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <span className="fa fa-spin fa-spinner" />
              ) : (
                'Register Identity'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default RegisterStakeholder;
