import React, { useEffect, useState } from 'react';
import { Card, Badge, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useAuth } from '../../contexts/AuthContext';
import numbro from 'numbro';
import { formatOptions } from '../../constants';
import './index.css';
import { useAccount } from '../../contexts/AccountContext';
import { NavLink } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';

export default function Offerings() {
  const { auth } = useAuth();
  const [offerings, setOfferings] = useState<any[]>([]);
  const [myOfferings, setMyOfferings] = useState<any[]>([]);
  const [availableOfferings, setAvailableOfferings] = useState<any[]>([]);
  const [promoImages, setPromoImages] = useState<{ [key: string]: string }>({});
  const { account } = useAccount();
  const { setError } = useError();

  useEffect(() => {
    const fetchOfferings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        const allOfferings = response.data.result;

        // Get current user's account ID
        const currentUserAccountId = account?.account_id;

        // Separate offerings
        const userOfferings = allOfferings.filter(
          (offering: any) => offering.account_id === currentUserAccountId
        );

        const otherOfferings = allOfferings.filter(
          (offering: any) => offering.account_id !== currentUserAccountId
        );

        // Update state
        setOfferings(allOfferings);
        setMyOfferings(userOfferings);
        setAvailableOfferings(otherOfferings);
      } catch (error) {
        console.error('Error fetching offerings:', error);
      }
    };

    fetchOfferings();
  }, [auth]);

  /**
   * Fetch promo images
   */
  useEffect(() => {
    const fetchPromoImages = async () => {
      try {
        const images: { [key: string]: string } = {};
        for (const offering of offerings) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/offerings/${offering.offering_id}/promo_image`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
              responseType: 'arraybuffer',
            }
          );
          const contentType = response.headers['content-type'];
          const base64Image = Buffer.from(response.data, 'binary').toString(
            'base64'
          );
          images[offering.offering_id] =
            `data:${contentType};base64,${base64Image}`;
        }
        setPromoImages(images);
      } catch (error) {
        setError(error);
        console.error('Error fetching promo images:', error);
      }
    };

    if (offerings.length > 0) {
      fetchPromoImages();
    }
  }, [auth, offerings]);

  const renderBadge = (status: any) => {
    switch (status) {
      case 'Approved':
        return (
          <Badge className="status-badge" bg="success">
            Active
          </Badge>
        );
      case 'Completed':
        return (
          <Badge className="status-badge" bg="primary">
            Completed
          </Badge>
        );
      case 'Cancelled':
        return (
          <Badge className="status-badge" bg="danger">
            Cancelled
          </Badge>
        );
      case 'Draft':
        return (
          <Badge className="status-badge" bg="secondary">
            Draft
          </Badge>
        );
      case 'Pending Approval':
        return (
          <Badge className="status-badge" bg="warning">
            Pending Approval
          </Badge>
        );
      case 'Paused':
        return (
          <Badge className="status-badge" bg="warning">
            Paused
          </Badge>
        );
      case 'Failed':
        return (
          <Badge className="status-badge" bg="danger">
            Failed
          </Badge>
        );
      case 'Closed':
        return (
          <Badge className="status-badge" bg="secondary">
            Closed
          </Badge>
        );
      case 'Withdrawn':
        return (
          <Badge className="status-badge" bg="dark">
            Withdrawn
          </Badge>
        );
      case 'Under Review':
        return (
          <Badge className="status-badge" bg="info">
            Under Review
          </Badge>
        );
      case 'Expired':
        return (
          <Badge className="status-badge" bg="dark">
            Expired
          </Badge>
        );
      default:
        return (
          <Badge className="status-badge" bg="secondary">
            Unknown
          </Badge>
        );
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom d-flex justify-content-between align-items-center">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <li className="breadcrumb-item active">Offerings</li>
              </li>
            </ol>
          </nav>
        </div>
        <div className="w-100 text-end">
          {account?.account_type === 'business_entity' && (
            <Link className="btn btn-primary" to="/offerings/create">
              New Offering
            </Link>
          )}
        </div>
      </div>
      <Container className="mt-5 px-5">
        <Row>
          {/* My Offerings Section */}
          {myOfferings.length > 0 && (
            <>
              <h2 className="mt-4">My Offerings</h2>
              <Row>
                {myOfferings.map((offering) => {
                  const investmentProgress =
                    (Number(offering?.total_investment) /
                      Number(offering?.soft_cap)) *
                    100;
                  const promoImageUrl = promoImages[offering.offering_id]; // Fallback to a default image if not loaded
                  return (
                    <Col md={4} key={offering?.offering_id} className="mb-4">
                      <Card>
                        <div className="image-wrapper">
                          <img src={promoImageUrl} className="card-img-top" />
                          {renderBadge(offering?.offering_status)}
                        </div>
                        <Card.Body>
                          <Card.Title>{offering?.offering_name}</Card.Title>
                          <Card.Text>
                            {offering?.offering_description}
                          </Card.Text>
                          Minimum Investment:{' '}
                          {offering?.minimum_investment &&
                            numbro(offering?.minimum_investment).format(
                              formatOptions
                            )}{' '}
                          USDC
                          <div className="progress mt-4" role="progressbar">
                            <div
                              className={`progress-bar ${
                                investmentProgress === 100
                                  ? 'bg-success'
                                  : 'bg-primary progress-bar-striped progress-bar-animated'
                              }`}
                              style={{
                                width: `${investmentProgress.toFixed(0)}%`,
                              }}
                            >
                              {`${investmentProgress.toFixed(0)}%`}
                            </div>
                          </div>
                          <small className="text-secondary">
                            {numbro(offering?.total_investment).format(
                              formatOptions
                            )}
                            /{numbro(offering?.soft_cap).format(formatOptions)}{' '}
                            USDC
                          </small>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between align-items-center">
                          <div className="text-muted">
                            Created at:{' '}
                            {new Date(
                              offering?.created_at
                            ).toLocaleDateString()}
                          </div>
                          <div>
                            {account?.account_id &&
                              account?.account_id === offering.account_id && (
                                <NavLink
                                  to={`${offering?.offering_id}/edit/details`}
                                  className="btn btn-secondary me-2"
                                  state={offering}
                                >
                                  Edit
                                </NavLink>
                              )}
                            <NavLink
                              to={`${offering?.offering_id}/details`}
                              className="btn btn-primary"
                              state={offering}
                            >
                              View
                            </NavLink>
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </>
          )}
        </Row>

        {/* Available Offerings Section */}
        <Row>
          {availableOfferings.length === 0 && (
            <div className="text-muted">
              There are currently no offerings to show.
            </div>
          )}
          {availableOfferings.map((offering) => {
            const investmentProgress =
              (Number(offering?.total_investment) /
                Number(offering?.soft_cap)) *
              100;
            const promoImageUrl = promoImages[offering.offering_id]; // Fallback to a default image if not loaded
            return (
              <Col md={4} key={offering?.offering_id} className="mb-4">
                <Card>
                  <div className="image-wrapper">
                    <img src={promoImageUrl} className="card-img-top" />
                    {renderBadge(offering?.offering_status)}
                  </div>
                  <Card.Body>
                    <Card.Title>{offering?.offering_name}</Card.Title>
                    <Card.Text>{offering?.offering_description}</Card.Text>
                    Minimum Investment:{' '}
                    {offering?.payment_terms?.minimum_investment &&
                      numbro(
                        offering?.payment_terms?.minimum_investment
                      ).format(formatOptions)}{' '}
                    USDC
                    <div className="progress mt-4" role="progressbar">
                      <div
                        className={`progress-bar ${
                          investmentProgress === 100
                            ? 'bg-success'
                            : 'bg-primary progress-bar-striped progress-bar-animated'
                        }`}
                        style={{ width: `${investmentProgress.toFixed(0)}%` }}
                      >
                        {`${investmentProgress.toFixed(0)}%`}
                      </div>
                    </div>
                    <small className="text-secondary">
                      {numbro(offering?.total_investment).format(formatOptions)}
                      /{numbro(offering?.soft_cap).format(formatOptions)} USDC
                    </small>
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-between align-items-center">
                    <div className="text-muted">
                      Created at:{' '}
                      {new Date(offering?.created_at).toLocaleDateString()}
                    </div>
                    <div>
                      {account?.account_id &&
                        account?.account_id === offering.account_id && (
                          <Link
                            to={`${offering?.offering_id}/edit/details`}
                            className="btn btn-secondary me-2"
                          >
                            Edit
                          </Link>
                        )}
                      <Link
                        to={`${offering?.offering_id}/details`}
                        className="btn btn-primary"
                      >
                        View
                      </Link>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}
