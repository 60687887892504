import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Container, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { useError } from '../../../contexts/ErrorContext';
import StockForm from './StockForm';
import AllocationForm from './InterestForm';
import { useEntity } from '../../../contexts/EntityContext';
import SafeForm from './SafeForm';

const NewDraft = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);
  const { entity } = useEntity();
  const location = useLocation();

  // State to hold draft type
  const [draftType, setDraftType] = useState<
    'Shares' | 'Unit Interests' | 'SAFE'
  >(
    (location.state as any)?.draftType ||
      (entity?.account_subtype === 'c_corporation'
        ? 'Shares'
        : 'Unit Interests')
  );

  // Initialize form data based on draft type
  const initialFormData =
    draftType === 'Shares'
      ? {
          name: '',
          email: '',
          numberOfShares: '',
          sharePrice: '',
          vestingSchedule: '',
          file: null,
          shareClassId: '',
          moduleData: {
            vestingSchedule: {},
            eventIds: [],
            vestedAmounts: [],
          },
        }
      : draftType === 'Unit Interests'
        ? {
            name: '',
            email: '',
            quantity: '',
            pricePerUnit: '',
            cashPaid: '',
            currency: '',
            issueDate: '',
            vestingSchedule: '',
            federalExemption: '',
          }
        : draftType === 'SAFE'
          ? {
              name: '',
              email: '',
              accountAddress: '',
              stakeholderType: '',
              relationship: '',
              stateOfResidency: '',
              employeeID: '',
              jobTitle: '',
              annualSalary: 0,

              // SAFE Details
              safeClass: '',
              quantity: 0,
              cashPaid: 0,
              pricePerShare: 0,
              paymentHash: '',
              boardApproval: '',
              issueDate: '',
              rule144Date: '',
              vestingStartDate: '',
              accelerationTerms: '',
              buildLegend: '',
              federalExemption: '',
              stateExemption: '',
              valueOfIPTransferred: 0,
              otherConsideration: '',
              rule144DifferenceReason: '',
              moduleData: null,
              file: null,
              notes: '',
              startDate: '',
              cliffDuration: '',
              vestingDuration: '',
              vestingInterval: '',
              eventIds: [],
              vestedAmounts: [],

              // Additional SAFE-specific fields
              discount: 0,
              valuationCap: 0,
              mostFavoredNation: false,
              currency: '',
              minimumInvestment: 0,
              investmentDeadline: '',
            }
          : {
              name: '',
              email: '',
              file: null,
            };

  const [formData, setFormData] = useState<any>(initialFormData);

  const handleChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | { name: string; value: any }
  ) => {
    let name: string;
    let value: any;

    if ('target' in e) {
      // It's a React.ChangeEvent
      name = e.target.name;
      value = e.target.value;
    } else {
      // It's a custom change object
      name = e.name;
      value = e.value;
    }

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Prepare form data to send to the backend
      const formToSubmit = new FormData();
      formToSubmit.append('draft_type', draftType);

      // Custom replacer function to handle BigInt serialization
      const replacer = (_key: string, value: any) => {
        if (typeof value === 'bigint') {
          return value.toString();
        }
        return value;
      };

      // Serialize formData with custom replacer
      formToSubmit.append('form_data', JSON.stringify(formData, replacer));

      // Append file if it exists
      if (formData.file) {
        formToSubmit.append('file', formData.file);
      }

      // Send request to create a new draft
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/drafts`,
        formToSubmit,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      const draft = response.data.draft;

      // Redirect to the review page with the draft ID
      navigate(`/equity/drafts/${draft.draft_id}/review`);
    } catch (error: any) {
      setError(error.response?.data?.message || error.message);
      error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to="/equity/drafts">Drafts</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {draftType}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <Container className="p-5">
        <Row>
          <Col md={3} />
          <Col md={6}>
            <h2>Create Draft</h2>
            <h5 className="my-3">New {draftType}</h5>
            {draftType === 'Unit Interests' ? (
              <AllocationForm
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                loading={loading}
              />
            ) : draftType === 'Shares' ? (
              <StockForm
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                loading={loading}
              />
            ) : draftType === 'SAFE' ? (
              <SafeForm
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                loading={loading}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewDraft;
