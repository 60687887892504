import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';

type Address = {
  line1: string;
  line2: string;
  line3: string;
  locality: string;
  sublocality: string;
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  country: string;
  country_code: string;
  postal_code: string;
};

type OnboardingState = {
  accountType?: string;
  accountSubtype?: string;
  ownerType?: string;
  ownerId?: string;
  onboarded?: boolean;
  entity?: {
    short_name: string;
    legal_name: string;
    entity_type: string;
    activity_types: string[];
    principal_address: Address;
    mailing_address: Address;
    registered_address: Address;
    registration_number: string;
    formation_doc_url: string;
    authorization_doc_url: string;
    identity: {
      wallet_address: string;
      identity_address: string;
      accredited: boolean;
    };
  };
  user?: {
    first_name: string;
    last_name: string;
    address: Address;
    identity: {
      wallet_address: string;
      identity_address: string;
      accredited: boolean;
    };
    identity_document: {
      date_of_birth: string;
      document_type: string;
      document_number: string;
      document_url: string;
      issue_date: string;
      expiration_date: string;
      status: number;
      verification_date: string;
    };
  };
  wallet?: {
    id: string;
    type: string;
    address: string;
    funded: boolean;
  };
};

type OnboardingProviderProps = {
  children: ReactNode;
};

type OnboardingContextType = {
  onboarding: OnboardingState | null; // Use null for not loaded or error state
  setOnboarding: React.Dispatch<React.SetStateAction<OnboardingState | null>>;
};

const OnboardingContext = createContext<OnboardingContextType | undefined>(
  undefined
);

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({
  children,
}) => {
  const { auth } = useAuth();
  const [onboarding, setOnboarding] = useState<OnboardingState | null>(() => {
    // Initialize state from localStorage
    const storedData = localStorage.getItem('capsign__onboarding');
    return storedData ? JSON.parse(storedData) : null;
  });

  React.useEffect(() => {
    // Update localStorage when onboarding state changes
    localStorage.setItem('capsign__onboarding', JSON.stringify(onboarding));
  }, [onboarding]);

  React.useEffect(() => {
    // Fetch onboarding status when auth token is available
    const fetchOnboardingStatus = async () => {
      try {
        if (auth?.token) {
          const response = await axios(
            `${process.env.REACT_APP_API_URL}/v1/users/me/onboarding_status`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth.user.account_id,
              },
            }
          );
          setOnboarding(response.data);
        }
      } catch (error) {
        console.log(error);
        // Consider clearing localStorage if needed
        // localStorage.removeItem('capsign__onboarding');
      }
    };

    fetchOnboardingStatus();
  }, []);

  const value = { onboarding, setOnboarding };

  return (
    <OnboardingContext.Provider value={value as any}>
      {children}
    </OnboardingContext.Provider>
  );
};
