import SubscriptionManager from '@capsign/contracts/artifacts/src/platform/SubscriptionManager.sol/SubscriptionManager.json';
import AgentManager from '@capsign/contracts/artifacts/src/securities/roles/permissioning/agent/AgentManager.sol/AgentManager.json';
import Auction from '@capsign/contracts/artifacts/src/markets/Auction.sol/Auction.json';
import Identity from '@capsign/contracts/artifacts/src/identity/Identity.sol/Identity.json';
import IdentityFactory from '@capsign/contracts/artifacts/src/identity/factory/IdFactory.sol/IdFactory.json';
import IdentityRegistry from '@capsign/contracts/artifacts/src/securities/registry/implementation/IdentityRegistry.sol/IdentityRegistry.json';
import IdentityRegistryStorage from '@capsign/contracts/artifacts/src/securities/registry/storage/IRStorage.sol/IRStorage.json';
import ImplementationAuthority from '@capsign/contracts/artifacts/src/identity/proxy/ImplementationAuthority.sol/ImplementationAuthority.json';
import ModularCompliance from '@capsign/contracts/artifacts/src/securities/compliance/modular/ModularCompliance.sol/ModularCompliance.json';
import VestingModule from '@capsign/contracts/artifacts/src/securities/compliance/modular/modules/VestingModule.sol/VestingModule.json';
import Stock from '@capsign/contracts/artifacts/src/securities/token/Stock.sol/Stock.json';
import ERC7752 from '@capsign/contracts/artifacts/src/securities/token/ERC7752.sol/ERC7752.json';
import TREXFactory from '@capsign/contracts/artifacts/src/securities/factory/TREXFactory.sol/TREXFactory.json';
import SAFE from '@capsign/contracts/artifacts/src/securities/token/SAFE.sol/SAFE.json';
import USD from '@capsign/contracts/artifacts/src/platform/USD.sol/USD.json';
import RegCF from '@capsign/contracts/artifacts/src/offerings/RegCF.sol/RegCF.json';
import RegD506b from '@capsign/contracts/artifacts/src/offerings/RegD506(b).sol/RegD506b.json';
import RegD506c from '@capsign/contracts/artifacts/src/offerings/RegD506(c).sol/RegD506c.json';
import SafeRegD506b from '@capsign/contracts/artifacts/src/offerings/SafeRegD506b.sol/SafeRegD506b.json';
import FundGateway from '@capsign/contracts/artifacts/src/securities/factory/FundGateway.sol/FundGateway.json';
import FundFactory from '@capsign/contracts/artifacts/src/securities/factory/FundFactory.sol/FundFactory.json';
import InterestClass from '@capsign/contracts/artifacts/src/securities/token/InterestClass.sol/InterestClass.json';
import { contracts as TREXContracts } from '@tokenysolutions/t-rex';
import { ContractInfo } from './types';
import { ContractInterface } from 'ethers';
import config from '../package.json';
export const version = config.version;

const USDC = {
  abi: [
    {
      inputs: [
        { internalType: 'string', name: '_name', type: 'string' },
        { internalType: 'string', name: '_symbol', type: 'string' },
        { internalType: 'uint8', name: '_decimals', type: 'uint8' },
        { internalType: 'address', name: '_underlying', type: 'address' },
        { internalType: 'address', name: '_vault', type: 'address' },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Approval',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'auth',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'timestamp',
          type: 'uint256',
        },
      ],
      name: 'LogAddAuth',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'oldOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'effectiveHeight',
          type: 'uint256',
        },
      ],
      name: 'LogChangeMPCOwner',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'oldVault',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newVault',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'effectiveTime',
          type: 'uint256',
        },
      ],
      name: 'LogChangeVault',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'txhash',
          type: 'bytes32',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'LogSwapin',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'bindaddr',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'LogSwapout',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256',
        },
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      inputs: [],
      name: 'DOMAIN_SEPARATOR',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'PERMIT_TYPEHASH',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'txhash', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'Swapin',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'address', name: 'bindaddr', type: 'address' },
      ],
      name: 'Swapout',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'TRANSFER_TYPEHASH',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'applyMinter',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'applyVault',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'approveAndCall',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'burn',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newVault', type: 'address' }],
      name: 'changeMPCOwner',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newVault', type: 'address' }],
      name: 'changeVault',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'delay',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'delayDelay',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'delayMinter',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'delayVault',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'deposit',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'deposit',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'deposit',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'depositVault',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'target', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
        { internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'depositWithPermit',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'target', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
        { internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'depositWithTransferPermit',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getAllMinters',
      outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_vault', type: 'address' }],
      name: 'initVault',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'isMinter',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'mint',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'minters',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'mpc',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'nonces',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pendingDelay',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pendingMinter',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pendingVault',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'target', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'permit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_auth', type: 'address' }],
      name: 'revokeMinter',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_auth', type: 'address' }],
      name: 'setMinter',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_vault', type: 'address' }],
      name: 'setVault',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bool', name: 'enabled', type: 'bool' }],
      name: 'setVaultOnly',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'transferAndCall',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'target', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'value', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'transferWithPermit',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'underlying',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'vault',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'withdraw',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'withdraw',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'withdraw',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'withdrawVault',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
};

// Load the JSON data
import base from './addresses.base.json';
import baseSepolia from './addresses.baseSepolia.json';
import localhost from './addresses.localhost.json';
import xrplEvmDevnet from './addresses.xrplEvmDevnet.json';
import { Abi } from 'viem';

export const contracts: {
  [key: number]: { [name: string]: ContractInfo };
} = {
  8453: {
    Identity: {
      address: base.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrplEvmDevnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: base.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityRegistryStorage: {
      address: baseSepolia.suite.identityRegistryStorage,
      abi: IdentityRegistryStorage.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: base.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: base.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: base.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    VestingModule: {
      address: base.compliance.vestingModule,
      abi: VestingModule.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: base.suite.subscriptionManager,
      abi: SubscriptionManager.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: base.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    Stock: {
      address: base.implementations.tokenImplementation,
      abi: Stock.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: base.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    USDC: {
      address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
      abi: USDC.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
    RegD506b: {
      address: '',
      abi: RegD506b.abi as Abi & ContractInterface,
    },
    RegD506c: {
      address: '',
      abi: RegD506c.abi as Abi & ContractInterface,
    },
    InterestClass: {
      address: '',
      abi: InterestClass.abi as Abi & ContractInterface,
    },
    ERC7752: {
      address: '',
      abi: ERC7752.abi as Abi & ContractInterface,
    },
  },
  31337: {
    Identity: {
      address: localhost.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrplEvmDevnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: localhost.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: localhost.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: localhost.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: localhost.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: localhost.suite.subscriptionManager,
      abi: SubscriptionManager.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: localhost.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    TREXGateway: {
      address: localhost.factories.trexGateway,
      abi: TREXContracts.TREXGateway.abi as Abi & ContractInterface,
    },
    Stock: {
      address: localhost.implementations.tokenImplementation,
      abi: Stock.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: localhost.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
    Token: {
      address: localhost.implementations.tokenImplementation,
      abi: TREXContracts.Token.abi as Abi & ContractInterface,
    },
    FundGateway: {
      address: localhost.factories.fundGateway,
      abi: FundGateway.abi as Abi & ContractInterface,
    },
    FundFactory: {
      address: localhost.factories.fundFactory,
      abi: FundFactory.abi as Abi & ContractInterface,
    },
    InterestClass: {
      address: '',
      abi: InterestClass.abi as Abi & ContractInterface,
    },
    ERC7752: {
      address: '',
      abi: ERC7752.abi as Abi & ContractInterface,
    },
    VestingModule: {
      address: localhost.compliance.vestingModule,
      abi: VestingModule.abi as Abi & ContractInterface,
    },
  },
  84532: {
    Identity: {
      address: baseSepolia.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrplEvmDevnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: baseSepolia.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityRegistryStorage: {
      address: baseSepolia.suite.identityRegistryStorage,
      abi: IdentityRegistryStorage.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: baseSepolia.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: baseSepolia.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: baseSepolia.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    VestingModule: {
      address: baseSepolia.compliance.vestingModule,
      abi: VestingModule.abi as Abi & ContractInterface,
    },
    AgentManager: {
      address: '',
      abi: AgentManager.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: baseSepolia.suite.subscriptionManager,
      abi: SubscriptionManager.abi as any,
    },
    TREXFactory: {
      address: baseSepolia.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    Stock: {
      address: baseSepolia.implementations.restrictedStockImplementation,
      abi: Stock.abi as Abi & ContractInterface,
    },
    ERC7752: {
      address: '',
      abi: ERC7752.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: baseSepolia.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    USDC: {
      address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
      abi: USDC.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
    RegD506b: {
      address: '',
      abi: RegD506b.abi as Abi & ContractInterface,
    },
    RegD506c: {
      address: '',
      abi: RegD506c.abi as Abi & ContractInterface,
    },
    SafeRegD506b: {
      address: '',
      abi: SafeRegD506b.abi as Abi & ContractInterface,
      bytecode: SafeRegD506b.bytecode,
    },
    InterestClass: {
      address: '',
      abi: InterestClass.abi as Abi & ContractInterface,
    },
    FundGateway: {
      address: baseSepolia.factories.fundGateway,
      abi: FundGateway.abi as Abi & ContractInterface,
    },
    FundFactory: {
      address: baseSepolia.factories.fundFactory,
      abi: FundFactory.abi as Abi & ContractInterface,
    },
  },
  1440002: {
    Identity: {
      address: xrplEvmDevnet.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrplEvmDevnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: xrplEvmDevnet.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: xrplEvmDevnet.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    Auction: {
      address: xrplEvmDevnet.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: localhost.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: xrplEvmDevnet.suite.subscriptionManager,
      abi: SubscriptionManager.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: xrplEvmDevnet.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    Stock: {
      address: xrplEvmDevnet.implementations.tokenImplementation,
      abi: Stock.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: xrplEvmDevnet.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
};

// NumbroJS
export const formatOptions = {
  thousandSeparated: true,
  optionalMantissa: true,
  trimMantissa: false,
  mantissa: 2,
};
