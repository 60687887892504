import React, { useEffect, useState } from 'react';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { Form, Container, Row, Col } from 'react-bootstrap';

export default function ViewSafeClass() {
  const { class_id } = useParams<{ class_id: string }>();
  const { auth } = useAuth();
  const [safeClass, setSafeClass] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    const fetchSafeClass = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/safe_classes/${class_id}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );
        setSafeClass(response.data.result);
      } catch (error: any) {
        console.error('Error fetching safe class:', error);
      } finally {
        setLoading(false);
      }
    };
    if (location.state.safeClass) {
      setSafeClass(location.state.safeClass);
      setLoading(false);
    } else {
      fetchSafeClass();
    }
  }, [auth, class_id]);

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/safes">Safes</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to="/safes/classes">Classes</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {safeClass?.class_name || 'Loading...'}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container className="my-5">
        {loading ? (
          <div className="text-center py-5">Loading...</div>
        ) : safeClass ? (
          <>
            <h2>{safeClass.class_name}</h2>
            <Form>
              <Form.Group controlId="formClassName" className="mb-3">
                <Form.Label>Class Name</Form.Label>
                <Form.Control
                  type="text"
                  value={safeClass.class_name}
                  disabled
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group controlId="formDiscount" className="mb-3">
                    <Form.Label>Discount (%)</Form.Label>
                    <Form.Control
                      type="number"
                      value={safeClass.discount}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formValuationCap" className="mb-3">
                    <Form.Label>Valuation Cap</Form.Label>
                    <Form.Control
                      type="number"
                      value={safeClass.valuation_cap}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formCurrency" className="mb-3">
                <Form.Label>Currency Address</Form.Label>
                <Form.Control type="text" value={safeClass.currency} disabled />
              </Form.Group>
              <Form.Group controlId="formURI" className="mb-3">
                <Form.Label>Metadata URI</Form.Label>
                <Form.Control type="text" value={safeClass.uri} disabled />
              </Form.Group>
              <Form.Group controlId="formTokenAddress" className="mb-3">
                <Form.Label>Token Address</Form.Label>
                <Form.Control
                  type="text"
                  value={safeClass.token_address}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="formTokenClassId" className="mb-3">
                <Form.Label>Token Class ID</Form.Label>
                <Form.Control
                  type="text"
                  value={safeClass.token_class_id}
                  disabled
                />
              </Form.Group>
            </Form>
          </>
        ) : (
          <div className="text-center py-5">Safe Class not found.</div>
        )}
      </Container>
    </>
  );
}
