import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useOffering } from '../../../contexts/OfferingContext';
import { useError } from '../../../contexts/ErrorContext';
import PDFViewer from './PDFViewer';
import { Container } from 'react-bootstrap';

export default function OfferingFinancials() {
  const [pdfData, setPdfData] = useState<any>(undefined);
  const { auth } = useAuth();
  const { offeringId } = useParams<{ offeringId: string }>();
  const [offering, setOffering] = useState<any>(undefined);
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any[]>([]);
  const [requiredDocuments, setRequiredDocuments] = useState<string[]>([
    'Form C',
    'Financial Statements',
    'Subscription Agreement',
    'Pitch Deck',
    'Use of Funds Statement',
    'Risk Factors Document',
    'Capitalization Table',
  ]);
  const { setError } = useError();

  /**
   * Fetch the current offering financial statements
   */
  useEffect(() => {
    const fetchOfferingDocuments = async () => {
      try {
        if (pdfData) return;

        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents`,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        // Financial Statements
        const documentId = response.data.result.filter(
          (r: any) => r.document_type === 'Financial Statements'
        )[0]?.document_id;

        if (documentId) {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/documents/${documentId}`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
              responseType: 'arraybuffer',
            }
          );
          setPdfData({ data: new Uint8Array(response.data) });
        }
      } catch (error) {
        console.error('Error fetching offering:', error);
      }
    };

    fetchOfferingDocuments();
  }, []);

  /**
   * Fetch the current offering using offeringId
   */
  useEffect(() => {
    const fetchOffering = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );
      setOffering(response.data.result);
    };
    fetchOffering();
  }, [offeringId, auth]);

  return (
    <Container className="mt-3">
      <h1>Financials</h1>
      <h3>Use of Funds</h3>
      <p className="border rounded p-2">{offering?.use_of_funds || 'N/A'}</p>
      <h3>Highlights</h3>
      <p className="border rounded p-2">
        CapSign developed and launched the application you are using from an
        initial $50k investment.
      </p>
      {pdfData && <PDFViewer data={pdfData} />}
    </Container>
  );
}
