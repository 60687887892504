import { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { formatOptions } from '../../constants';
import numbro from 'numbro';
import { useTheme } from '../../contexts/ThemeContext';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { useEntity } from '../../contexts/EntityContext';

function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export default function Dashboard() {
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [outstandingShares, setOutstandingShares] = useState<number>();
  const { theme } = useTheme();
  const { auth } = useAuth();
  const { entity } = useEntity();
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());

  const fetchCapTableData = async (date: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/cap_tables/me/data`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
          params: { date: format(new Date(date), 'yyyy-MM-dd') }, // Ensure correct date format
        }
      );
      const capTable = response.data.result.cap_table;
      setShareClasses(response.data.result.share_classes);
      setOutstandingShares(response.data.result.outstanding_shares);
    } catch (error) {
      console.error('Error fetching cap table data:', error);
    }
  };

  useEffect(() => {
    fetchCapTableData(selectedDate);
  }, [selectedDate, auth]);

  const sharesOrInterests =
    entity.account_subtype === 'c_corporation' ? 'Shares' : 'Unit Interests';
  const shareOrInterest =
    entity.account_subtype === 'c_corporation' ? 'Share' : 'Unit Interest';

  const tokenizedSharesOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'pie',
      backgroundColor: theme === 'dark' ? '#121212' : '#ffffff',
    },
    title: {
      text: `Tokenized ${sharesOrInterests} by Class`,
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    series: [
      {
        name: sharesOrInterests,
        data: shareClasses.map((shareClass) => {
          return {
            name: shareClass.class_name,
            y: Number(shareClass.quantity),
            color: shareClass.color,
          };
        }),
      },
    ],
    tooltip: {
      formatter: function () {
        return `<b>${(this as any).point.name}</b><br/>
                <span style="color:${(this as any).point.color}">●</span> 
                ${numbro((this as any).y).format({ thousandSeparated: true })} ${sharesOrInterests}`;
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          formatter: function () {
            return `<b>${(this as any).point.name}</b>: ${numbro(
              (this as any).y
            ).format(formatOptions)}`;
          },
        },
      },
    },
  };

  const totalVolumeOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'pie',
      backgroundColor: theme === 'dark' ? '#121212' : '#ffffff',
    },
    title: {
      text: 'Total Volume by Class',
      style: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      },
    },
    series: [
      {
        name: 'Volume',
        data: [],
      },
    ],
    plotOptions: {
      pie: {
        dataLabels: {
          formatter: function () {
            return `<b>${(this as any).point.name}</b>: $${numbro(
              (this as any).y
            ).format(formatOptions)}`;
          },
        },
      },
    },
  };

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="date"
                defaultValue={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
            <div className="d-flex gap-2">
              <NavLink to="/equity/create" className="btn btn-primary">
                New Class
              </NavLink>
              <NavLink to="drafts" className="btn btn-primary">
                Tokenize
              </NavLink>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-around">
            <HighchartsReact
              highcharts={Highcharts}
              options={tokenizedSharesOptions}
            />
            <HighchartsReact
              highcharts={Highcharts}
              options={totalVolumeOptions}
            />
          </div>
          <Table striped bordered>
            <thead>
              <tr>
                <th>{shareOrInterest} Class</th>
                <th>Tokenized {sharesOrInterests}</th>
                <th>Total Volume</th>
                {/* Add other relevant columns here */}
              </tr>
            </thead>
            <tbody>
              {shareClasses.map((shareClass, index) => {
                return (
                  <tr key={index}>
                    <td>{shareClass.class_name}</td>
                    <td>{numbro(shareClass.quantity).format(formatOptions)}</td>
                    <td>-</td>
                  </tr>
                );
              })}
              <tr>
                <td>Total</td>
                <td>{numbro(outstandingShares || 0).format(formatOptions)}</td>
                <td>$0.00</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
