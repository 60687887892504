import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useOnboarding } from '../../../contexts/OnboardingContext';

export default function RegistrationNumber(props: any) {
  const [registrationNumber, setRegistrationNumber] = useState('');
  const { auth } = useAuth();
  const { onboarding } = useOnboarding();
  const navigate = useNavigate();

  const onSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      const headers = {
        Authorization: `Bearer ${auth?.token}`,
        'X-Account-ID': auth?.user.account_id,
      };
      const data = {
        registration_number: registrationNumber,
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/entities/me/registration_number`,
        data,
        {
          headers: headers,
        }
      );

      // Assuming you want to navigate the user to the next step in your onboarding process after successful submission
      navigate('/onboarding/entity/corporate-authorization');
    } catch (error: any) {
      console.error('Error updating registration number:', error);
      // Handle error case, possibly setting an error state and displaying it to the user
    }
  };

  return (
    <div className="row">
      <Form className="col-lg-4 offset-lg-4 col-md-12 py-5" onSubmit={onSubmit}>
        <h5 className="mb-4">Registration Number</h5>
        <Form.Group className="mb-3" controlId="registrationNumberInput">
          <Form.Control
            type="text"
            placeholder="Enter registration number"
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
          />
          <Form.Text className="text-muted">
            Your corporate registration number as officially recorded.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" className="w-100" type="submit">
          Continue
        </Button>
      </Form>
    </div>
  );
}
