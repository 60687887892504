import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useOnboarding } from '../../../contexts/OnboardingContext';
import { useError } from '../../../contexts/ErrorContext';

export default function Details(props: any) {
  const [legalName, setLegalName] = useState('');
  const [shortName, setShortName] = useState('');
  const [activityTypes, setActivityTypes] = useState<string[]>([]);

  const { auth } = useAuth();
  const { onboarding } = useOnboarding();
  const navigate = useNavigate();
  const { setError } = useError();

  const onSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior

    // If no activity types are selected, prevent submission
    if (activityTypes.length === 0) {
      setError('Please select at least one intended activity.');
      return;
    }

    // If it's already set, continue to next part
    if (
      onboarding?.entity?.legal_name &&
      onboarding?.entity?.short_name &&
      onboarding?.entity?.activity_types
    ) {
      return navigate('/onboarding/entity/registered-address');
    }

    try {
      // Create entity and entity user account
      const data = {
        legal_name: legalName,
        short_name: shortName,
        activity_types: activityTypes,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/v1/entities',
        data,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-ID': auth?.user.account_id,
          },
        }
      );

      navigate('/onboarding/entity/registered-address');
    } catch (error: any) {
      console.error('Error creating account:', error);
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (onboarding?.entity?.short_name) {
      setShortName(onboarding?.entity?.short_name);
      setLegalName(onboarding?.entity?.legal_name);
      setActivityTypes(onboarding?.entity?.activity_types);
    }
  }, [onboarding?.entity?.short_name]);

  return (
    <div className="row">
      <Form className="col-lg-4 offset-lg-4 col-md-12 pb-5" onSubmit={onSubmit}>
        <>
          <h5>Entity Details</h5>
          <Form.Group controlId="entityLegalName" className="mt-3">
            <Form.Label>Legal Name</Form.Label>
            <Form.Control
              value={onboarding?.entity?.legal_name || legalName}
              onChange={(event: any) => {
                setLegalName(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="entityShortName" className="mt-3">
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              value={shortName}
              onChange={(event: any) => {
                setShortName(event.target.value);
              }}
            />
          </Form.Group>

          {/* Initially only supporting investment of capital into CapSign Inc. */}

          <Form.Group controlId="activityCheckboxes" className="mt-3">
            <Form.Label>Intended Activity</Form.Label>
            <div>
              <Form.Check
                type="checkbox"
                label="Raise Capital"
                value="Issuer"
                checked={activityTypes.includes('Issuer')}
                onChange={(event) => {
                  const value = event.target.value;
                  setActivityTypes((prev) =>
                    prev.includes(value)
                      ? prev.filter((val) => val !== value)
                      : [...prev, value]
                  );
                }}
              />
              <Form.Check
                type="checkbox"
                label="Manage Stakeholders"
                value="Manager"
                checked={activityTypes.includes('Manager')}
                onChange={(event) => {
                  const value = event.target.value;
                  setActivityTypes((prev) =>
                    prev.includes(value)
                      ? prev.filter((val) => val !== value)
                      : [...prev, value]
                  );
                }}
              />
              <Form.Check
                type="checkbox"
                label="Make Investments"
                value="Investor"
                checked={activityTypes.includes('Investor')}
                onChange={(event) => {
                  const value = event.target.value;
                  setActivityTypes((prev) =>
                    prev.includes(value)
                      ? prev.filter((val) => val !== value)
                      : [...prev, value]
                  );
                }}
              />
            </div>
          </Form.Group>
          <Form.Text className="text-muted">
            Note: We currently only support investment in CapSign Inc.
          </Form.Text>
        </>
        <Button
          variant="primary"
          className="mt-5 w-100"
          type="submit"
          disabled={
            !(onboarding?.entity?.legal_name || (legalName && shortName))
          }
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}
