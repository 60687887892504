import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import Dashboard from './Dashboard';
import { useChainId } from 'wagmi';
import emptyIcon from './empty.png';
import { useEntity } from '../../contexts/EntityContext';

export default function Equity() {
  const { auth } = useAuth();
  const chainId = useChainId();
  const { entity } = useEntity();
  const [shareClasses, setShareClasses] = useState<any>([]);
  const [interestClasses, setInterestClasses] = useState<any>([]);
  const [hasToken, setHasToken] = useState<boolean>(false);
  const [safeContract, setSafeContract] = useState<string | null>(null);
  /**
   * Fetch share classes
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/me/share_classes`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setShareClasses(result);
          for (let shareClass of result) {
            if (shareClass.token_address) setHasToken(true);
          }
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  /**
   * Fetch interest classes
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/me/interest_classes`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setInterestClasses(result);
          for (let interestClass of result) {
            if (interestClass.token_id) setHasToken(true);
          }
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  /**
   * Fetch SAFE contract
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/entities/me/safe_contract`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setSafeContract(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  return (
    <>
      {/* Breadcrumb */}
      <div className="border-bottom">
        <div className="container-fluid py-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <li className="breadcrumb-item active">Equity</li>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Container fluid>
        {hasToken ? (
          <>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="mb-3">
                {interestClasses.map((interestClass: any, index: number) => (
                  <NavLink
                    to={`/equity/${interestClass.token_address}`}
                    className="text-decoration-none"
                    key={index}
                    state={{
                      interestClass,
                      entity,
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center border rounded shadow-sm p-4 my-3">
                      <div className="text-truncate">
                        <span className="text-dark">
                          {interestClass.class_name}
                        </span>
                        <br />
                      </div>
                      {/* TODO: Link to explorer */}
                      <div className="d-none d-md-block">
                        {interestClass.token_address}
                      </div>
                    </div>
                  </NavLink>
                ))}
                {shareClasses.map((shareClass: any, index: number) => (
                  <NavLink
                    to={`/equity/${shareClass.token_address}`}
                    className="text-decoration-none"
                    key={index}
                    state={{
                      shareClass,
                      entity,
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center border rounded shadow-sm p-4 my-3">
                      <div className="text-truncate">
                        <span className="text-dark">
                          {shareClass.class_name}
                        </span>
                        <br />
                      </div>
                      {/* TODO: Link to explorer */}
                      <div className="d-none d-md-block">
                        {shareClass.token_address}
                      </div>
                    </div>
                  </NavLink>
                ))}
              </Col>
            </Row>
            <Dashboard />
          </>
        ) : (
          <Row className="h-100">
            <Col md={{ span: 8, offset: 2 }} className="my-4">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Equity Management Dashboard</h4>
                <NavLink to="/equity/create" className="btn btn-primary">
                  New Token
                </NavLink>
              </div>
              <div className="justify-content-center align-items-center d-flex mt-5">
                <img src={emptyIcon} className="img-fluid" />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
